<template>
  <v-app class="fontRedHat">
    <iframeSsoAuth> </iframeSsoAuth>

    <v-app-bar
      app
      color="white"
      scroll-threshold="50000"
      :shrink-on-scroll="false"
      elevation="0"
      style="border-bottom: 2px solid rgb(231, 236, 241) !important"
    >
      <!-- v-if="connected" -->

      <!-- // TODO : col en fonction des larguer d'écran sm, xs, .... -->
      <v-row no-gutters style="flex-wrap: nowrap">
        <!-- Colonne pour la partie gauche : burger menu et logo -->
        <v-col
          cols="1"
          class="ml-5 mr-6 flex-grow-0 flex-shrink-0 justify-center"
        >
          <!-- Logo avec retour accueil -->
          <div class="d-flex align-center" style="height: 100%">
            <a :href="onClickHomeButton()" class="d-flex align-center">
              <img src="./assets/login.png" height="45" />
            </a>
          </div>
        </v-col>

        <!-- Colonne pour la partie centrale : les boutons -->
        <v-col cols="9" class="flex-grow-1 flex-shrink-1">
          <MenuNavButtonComponent app> </MenuNavButtonComponent>
        </v-col>

        <!-- Colonne pour la partie droite : nom user + menu de déconnexion -->
        <v-col cols="2" class="flex-grow-0 flex-shrink-0">
          <div class="d-flex align-center justify-end" style="height: 100%">
            <!-- Menu pour l'adresse mail de l'utilisateur et ses actions -->
            <MenuProfilComponent
              :connected="connected"
              class="d-flex justify-self-end"
            ></MenuProfilComponent>
          </div>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-main>
      <v-row no-gutters class="d-flex flex-nowrap align-stretch fill-height">
        <BurgerMenuComponent :connected="connected"> </BurgerMenuComponent>

        <!-- Partie centrale -->
        <!-- v-if="connected" -->
        <v-col>
          <v-row
            no-gutters
            style="border-bottom: 2px solid rgb(231, 236, 241) !important"
          >
            <!-- Colonne pour la partie centrale qui devient une ligne en dessous a part entière : titre de l'application -->
            <div class="d-flex justify-start align-center">
              <div class="d-flex pa-2">
                <span
                  class="text-h6"
                  style="font-family: 'Red hat text', 'sans-serif' !important"
                  >{{ title }}</span
                >
              </div>
            </div>
          </v-row>

          <v-container fluid>
            <router-view></router-view>
          </v-container>
        </v-col>
      </v-row>
    </v-main>

    <v-footer padless class="secondary">
      <v-row no-gutters style="flex-wrap: nowrap">
        <!-- Colonne pour la partie gauche : la liste des apis -->
        <v-col cols="2" class="flex-grow-0 flex-shrink-0">
          <div class="d-flex">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" text v-show="mode">
                  <span class="text-lowercase white--text">apis</span>
                  <v-icon color="primary">mdi-menu-up</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(url, index) in urlApis" :key="index">
                  <v-list-item-title>{{ url.label }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-col>

        <!-- Colonne pour la partie centrale : la plateforme de dev ou preprod -->
        <v-col cols="8" class="flex-grow-1 flex-shrink-1">
          <div
            class="d-flex justify-center align-center"
            style="height: 100%"
            v-if="mode"
          >
            <v-sheet width="18" height="18" :color="getColorMode()"></v-sheet>
            <div :class="getClassMode()" style="font-size: 12px">
              {{ getModeLabel() }}
            </div>
          </div>
        </v-col>

        <!-- Colonne pour la partie droite : le numéro de version -->
        <v-col cols="2" class="text-right flex-grow-0 flex-shrink-0">
          <div
            class="mr-2 font-weight-light white--text d-flex align-center justify-end"
            style="height: 100%"
          >
            version {{ version }}
          </div>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import { defines } from "@/router/defines.js";
import * as loginStoreService from "@/service/login_store_service.js";
import { roleCanEdit } from "@/service/role_service.js";

import iframeSsoAuth from "@/components/iframeSsoAuth.vue";
import BurgerMenuComponent from "@/components/ui/BurgerMenuComponent.vue";
import MenuNavButtonComponent from "@/components/ui/MenuNavButtonComponent.vue";
import MenuProfilComponent from "@/components/ui/MenuProfilComponent.vue";
import * as ssoService from "@/service/sso_service.js";

import * as logger from "@/tools/logger.js";
import { getAuth, onAuthStateChanged } from "firebase/auth";

export default {
  name: "App",

  components: {
    iframeSsoAuth,
    BurgerMenuComponent,
    MenuNavButtonComponent,
    MenuProfilComponent,
  },

  data: () => ({
    /**Afficher le burger menu */
    showBurgerMenu: false,

    // role d'édition
    canEdit: false,

    // Indicateur pour savoir si l'utilisateur est connecté ou pas
    connected: false,

    title: "",

    /** Les urls utilisées */
    urlApis: [],

    routes: defines,

    /** Détecte le mode dev ou prod */
    mode: process.env.NODE_ENV === "development",

    /**est-ce que la session a expiré? */
    sessionHasExpired: false,

    /**le numéro de version applicative (à changer à chaque fois) */
    version: "2.16.0",
  }),
  computed: {},
  watch: {
    showBurgerMenu() {},
  },
  methods: {
    /** Mise à jour de l'état de connexion de l'utilisateur */
    isConnected() {
      // Récupération de l'état de connexion de l'utilisateur
      let c = loginStoreService.getConnected();

      // Parsing du string en boolean
      this.connected = c === "true" ? true : false;
    },

    roleEdit() {
      //est-ce que l'utilisateur peut éditer les données
      this.canEdit = roleCanEdit();

      if (this.canEdit) {
        this.title = "Consulter et modifier la Database Globale";
      } else {
        this.title = "Consulter la Database Globale";
      }
    },

    /** Evènement de clic sur le bouton home dans l'appbar */
    onClickHomeButton() {
      let url = process.env.VUE_APP_BASE_SITE_AVEC;

      if (url && url != "") {
        return url;
      }
      logger.debug("url Avec is null undefined or empty");
    },

    getGivenName() {
      return loginStoreService.getGivenName();
    },

    /** Complete le tableau des urls des api en fonction du fichier de conf */
    completeUrlApis() {
      this.urlApis.push({
        label: "MODE : " + process.env.VUE_APP_MODE_API,
      });
      this.urlApis.push({
        label: "Api SFR : " + process.env.VUE_APP_URL_API_SFR,
      });
      this.urlApis.push({
        label:
          "Api Etablissement : " + process.env.VUE_APP_URL_API_ETABLISHMENT,
      });
      this.urlApis.push({
        label: "Api Dictionnaires : " + process.env.VUE_APP_URL_API_DICTIONARY,
      });
      this.urlApis.push({
        label: "Api Users : " + process.env.VUE_APP_URL_API_USER,
      });
      this.urlApis.push({
        label: "Api Conf : " + process.env.VUE_APP_URL_API_CONF,
      });
      this.urlApis.push({
        label: "Api Practitioner : " + process.env.VUE_APP_URL_API_PRACTITIONER,
      });
      this.urlApis.push({
        label: "Api backend : " + process.env.VUE_APP_URL_API_BACKEND,
      });
      this.urlApis.push({
        label:
          "Url Iframe : " + process.env.VUE_APP_URL_IFRAME + "/iFrameAuth.html",
      });
    },

    getModeLabel() {
      return process.env.VUE_APP_MODE_API.toUpperCase();
    },
    getClassMode() {
      return "px-2 white--text";
    },
    getColorMode() {
      switch (process.env.VUE_APP_MODE_TYPE) {
        case "DEVELOPMENT":
          return "purple";
        case "DG_DEV":
          return "red";
        case "PREPROD":
          return "orange";
        case "PRODUCTION":
          return "yellow";
      }

      return "white";
    },
  },
  mounted() {
    // Spécific listener firebase auth pour le on state changed()
    const auth = getAuth();
    onAuthStateChanged(auth, () => {
      logger.debug("onStateChange user  ");
    });

    // Ajout listener pour la reception de l'authentification venant de l'ifrme dans toute l'app.
    window.addEventListener("message", ssoService.receiveMessage);

    // fabrique le tableau des urls
    this.completeUrlApis();

    console.log("Application version " + this.version);
  },
  updated() {
    this.isConnected();
    this.roleEdit();
  },
};
</script>

<style>
.field-uppercase input {
  text-transform: uppercase;
}

.field-lowercase input {
  text-transform: lowercase;
}

.capitalize:first-letter {
  text-transform: capitalize;
}

.bgred {
  background-color: red;
}
.bgblue {
  background-color: blue;
}

.bgyellow {
  background-color: yellow;
}
.bggreen {
  background-color: green;
}
.main-title {
  font-family: "Red Hat Text", sans-serif;
  font-weight: 500;
  font-size: 28px;
}
.medium {
  font-weight: 500;
}

.btn {
  /* Pour surcharger le libellé en majuscule de material design. */
  text-transform: unset !important;
}

.label-field {
  font-size: 14px;
}
</style>
