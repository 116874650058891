import * as logger from "@/tools/logger.js";

export default class DgAuthService {
  constructor(api) {
    this.api = api;
  }

  /** Retourne un token définissant les rôles d'un collaborateur. */
  async getRolesForUser() {
    return this.api.getRolesForUser();
  }

  /** Retourne les rôles d'un collaborateur. */
  async getDetailForUser(userId) {
    return this.api.getDetailForUser(userId);
  }

  // /** Retourne les rôles de tous les collaborateurs ayant des rôles spécifiques. */
  // async getDetailForAllCollaborater() {
  //   let result = await this.api.getDetailForAllCollaborater();
  //   return result.info;
  // }

  // /** Retourne les rôles d'un collaborateur. */
  // async getDetailForCollaborater(userId) {
  //   let result = await this.api.getDetailForCollaborater(userId);
  //   return result.info;
  // }

  // /** Met à jour les rôles pour un collaborateur. */
  // async updateRolesForCollaborater(entity, entityId) {
  //   return this.api
  //     .updateRolesForCollaborater(entity, entityId)
  //     .catch((error) => {
  //       let converter = new statusMapper.StatusConverter();
  //       converter.add404(entity.id);
  //       converter.convert(error);
  //     });
  // }

  // /** Obtient les rôles pour les clients */
  // async getRolesForCustomer() {
  //   let result = await this.api.getRolesForCustomer();
  //   return result.customer;
  // }

  // /** Met à jour les rôles pour un client. */
  // async updateRolesForCustomer(roles) {
  //   return this.api.updateRolesForCustomer(roles).catch((error) => {
  //     let converter = new statusMapper.StatusConverter();
  //     converter.add404();
  //     converter.convert(error);
  //   });
  // }

  // /** Retourne les rôles pour l'entité "services". */
  // async getRolesForServices() {
  //   let result = await this.api.getRolesForServices();
  //   return result.services;
  // }

  // /** Met à jour les rôles pour l'entité "services". */
  // async updateRolesForServices(entity) {
  //   return this.api.updateRolesForServices(entity).catch((error) => {
  //     let converter = new statusMapper.StatusConverter();
  //     converter.add404(entity.id);
  //     converter.convert(error);
  //   });
  // }

  // /** Retourne les rôles pour les fonctions. */
  // async getRolesForFonctions() {
  //   let result = await this.api.getRolesForFonctions();

  //   return result.functions;
  // }

  /** Met à jour les rôles pour l'entité "fonction". */
  /* async updateRolesForFunction(entity) {
    return this.api.updateRolesForFunction(entity).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(entity.id);
      converter.convert(error);
    });
  }*/

  // /** Retourne la liste des rôles avec l'héritage. */
  // async getRoles() {
  //   let result = await this.api.getRoles();

  //   return result.roles;
  // }

  /**A partir de la liste des rôles de référence et d'une liste de noms de rôles : retourne la liste de
   * noms de rôles complétée avec l'héritage.
   */
  buildInheritance(inheritance, roleNames) {
    let roles = [];

    for (let roleName of roleNames) {
      let target = inheritance.find((r) => r.name === roleName);

      let role = {};

      role.name = roleName;
      role.herited = false;

      roles.push(role);

      //on rajoute les rôles hérités
      for (let granted of target.grant) {
        if (
          !roleNames.includes(granted) &&
          !roles.find((r) => r.name === granted)
        ) {
          role = {};

          role.name = granted;
          role.herited = true;

          roles.push(role);
        }
      }
    }

    return roles;
  }

  foo() {
    logger.debug("foo");
  }
}
