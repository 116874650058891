import { RolesApplicationEnum } from "../service/roles/roles_application.js";

export const defines = {
  /**Étape de connexion/login */
  connexion: {
    path: "/connexion",
    name: "connexion",
  },

  /** Etape de chargement de la connexion */
  loadingConnection: {
    path: "/chargementconnexion",
    name: "chargementconnexion",
    component: "../views/LoadingConnection.vue",
  },

  /** Page d'accueil */
  home: {
    path: "/Accueil",
    name: "Accueil",
    component: "../views/Home.vue",
  },

  /** Page d'accueil de la partie configuration */
  confAppsHome: {
    path: "/accueilconfiguration",
    name: "accueilconfiguration",
    component: "../views/confApps/confAppsHome.vue",
    roles: [RolesApplicationEnum.ReadConfAppMenu],
  },

  /** Page d'accueil de la partie dgauth */
  confDgauth: {
    path: "/accueildgauth",
    name: "accueildgauth",
    component: "../views/dgauth/dgauthHome.vue",
    roles: [RolesApplicationEnum.ReadDgauth],
  },

  /** Page d'acceuil de la partie gestion des praticiens */
  confPractitioners: {
    path: "/accueilpraticiens",
    name: "acceuilpraticiens",
    component: "../views/practitioners/practitionersHome.vue",
    roles: [RolesApplicationEnum.ReadPracticioner],
  },

  confAppViewEditDG: {
    view: {
      path: "/consultermodifierdg",
      name: "consultermodifierdg",
      roles: [RolesApplicationEnum.ReadUserDroitApplicatif],
    },
  },
  confAppWorksContract: {
    view: {
      path: "/contratdetravail",
      name: "contratdetravail",
      roles: [RolesApplicationEnum.EditConfAppContratTravail],
    },
  },
  confAvecEstablishment: {
    view: {
      path: "/etablissementavec",
      name: "etablissementavec",
      roles: [RolesApplicationEnum.EditConfEstablishmentAvec],
    },
  },
  confAvecSuperEstablishment: {
    view: {
      path: "/superetablissementavec",
      name: "superetablissementavec",
      roles: [RolesApplicationEnum.EditConfSuperEstablishmentAvec],
    },
  },
  confAvecSubactivity: {
    view: {
      path: "/subactivityavec",
      name: "subactivityavec",
      roles: [RolesApplicationEnum.EditConfSubactivityAvec],
    },
  },
  confAvecIndicators: {
    view: {
      path: "/labelactivitesavec",
      name: "labelactivitesavec",
      roles: [RolesApplicationEnum.ReadConf],
    },
  },
  confAvecKeyLabelsActivities: {
    view: {
      path: "/keylabelactivitesavec/consulter/:id",
      root: "/keylabelactivitesavec/consulter",
      name: "keylabelactivitesavec.view",
      roles: [RolesApplicationEnum.ReadConf],
    },
    table: {
      path: "/keylabelactivitesavec",
      name: "keylabelactivitesavec.table",
      roles: [RolesApplicationEnum.ReadConf],
    },
    add: {
      path: "/keylabelactivitesavec/ajouter",
      name: "keylabelactivitesavec.add",
      roles: [RolesApplicationEnum.EditConf],
    },
    edit: {
      path: "/keylabelactivitesavec/editer/:id",
      root: "keylabelactivitesavec/editer",
      name: "keylabelactivitesavec.edit",
      roles: [RolesApplicationEnum.EditConf],
    },
  },
  confEstablishmentBusinessApp: {
    table: {
      path: "/appmetieretablissement",
      name: "appmetieretablissement",
      roles: [RolesApplicationEnum.ReadConfEstablishmentBusinessApp],
    },
  },
  permission: {
    path: "/permission",
    name: "permission",
  },

  // Les actionnaires
  stockholders: {
    view: {
      path: "/ActionnairesMembresAdherents/consulter/:id",
      root: "/ActionnairesMembresAdherents/consulter",
      name: "actionnaires.view",
      roles: [RolesApplicationEnum.ReadJuridique],
    },
    table: {
      path: "/ActionnairesMembresAdherents",
      name: "actionnaires.table",
      roles: [RolesApplicationEnum.ReadJuridique],
    },
    add: {
      path: "/ActionnairesMembresAdherents/ajouter",
      name: "actionnaires.add",
      roles: [RolesApplicationEnum.EditJuridique],
    },
    edit: {
      path: "/ActionnairesMembresAdherents/editer/:id",
      root: "ActionnairesMembresAdherents/editer",
      name: "actionnaires.edit",
      roles: [RolesApplicationEnum.EditJuridique],
    },
  },

  // Les acronymes
  acronyms: {
    view: {
      path: "/acronymes/consulter/:id",
      root: "/acronymes/consulter",
      name: "acronymes.view",
      roles: [RolesApplicationEnum.ReadDictionary],
    },
    table: {
      path: "/acronymes",
      name: "acronymes.table",
      roles: [RolesApplicationEnum.ReadDictionary],
    },
    add: {
      path: "/acronymes/ajouter",
      name: "acronymes.add",
      roles: [RolesApplicationEnum.EditDictionary],
    },
    edit: {
      path: "/acronymes/editer/:id",
      root: "acronymes/editer",
      name: "acronymes.edit",
      roles: [RolesApplicationEnum.EditDictionary],
    },
  },

  // Les activités
  activities: {
    view: {
      path: "/activites/consulter/:id",
      root: "/activites/consulter",
      name: "activites.view",
      roles: [RolesApplicationEnum.ReadActivities],
    },
    table: {
      path: "/activites",
      name: "activites.table",
      roles: [RolesApplicationEnum.ReadServiceSFR],
    },
    add: {
      path: "/activites/ajouter",
      name: "activites.add",
      roles: [RolesApplicationEnum.EditServiceSFR],
    },
    edit: {
      path: "/activites/editer/:id",
      root: "activites/editer",
      name: "activites.edit",
      roles: [RolesApplicationEnum.EditActivities],
    },
  },

  /** les super activités */
  superActivities: {
    view: {
      path: "/superactivites/consulter/:id",
      root: "/superactivites/consulter",
      name: "superactivites.view",
      roles: [RolesApplicationEnum.ReadServiceSFR],
    },
    table: {
      path: "/superactivites",
      name: "superactivites.table",
      roles: [RolesApplicationEnum.ReadServiceSFR],
    },
    add: {
      path: "/superactivites/ajouter",
      name: "superactivites.add",
      roles: [RolesApplicationEnum.EditServiceSFR],
    },
    edit: {
      path: "/superactivites/editer/:id",
      root: "superactivites/editer",
      name: "superactivites.edit",
      roles: [RolesApplicationEnum.EditServiceSFR],
    },
  },

  /**les civilités */
  civilities: {
    view: {
      path: "/civilites/consulter/:id",
      root: "/civilites/consulter",
      name: "civilites.view",
      roles: [RolesApplicationEnum.ReadUser],
    },
    table: {
      path: "/civilites",
      name: "civilites.table",
      roles: [RolesApplicationEnum.ReadUser],
    },
    edit: {
      path: "/civilites/editer/:id",
      root: "/civilites/editer",
      name: "civilites.edit",
      roles: [RolesApplicationEnum.EditUser],
    },
    add: {
      path: "/civilites/ajouter",
      name: "civilites.add",
      roles: [RolesApplicationEnum.EditUser],
    },
  },

  /** contacts de contrat externe */
  externalContractContact: {
    view: {
      path: "/contactscontratexterne/consulter/:id",
      root: "/contactscontratexterne/consulter",
      name: "contactscontratexterne.view",
      roles: [RolesApplicationEnum.ReadJuridique],
    },
    table: {
      path: "/contactscontratexterne",
      name: "contactscontratexterne.table",
      roles: [RolesApplicationEnum.ReadJuridique],
    },
    edit: {
      path: "/contactscontratexterne/editer/:id",
      root: "/contactscontratexterne/editer",
      name: "contactscontratexterne.edit",
      roles: [RolesApplicationEnum.EditJuridique],
    },
    add: {
      path: "/contactscontratexterne/ajouter",
      name: "contactscontratexterne.add",
      roles: [RolesApplicationEnum.EditJuridique],
    },
  },

  /** contexte notes de frais */
  expenseReportContext: {
    view: {
      path: "/contextenotedefrais/consulter/:id",
      root: "/contextenotedefrais/consulter",
      name: "contextenotedefrais.view",
      roles: [RolesApplicationEnum.ReadDictionary],
    },
    table: {
      path: "/contextenotedefrais",
      name: "contextenotedefrais.table",
      roles: [RolesApplicationEnum.ReadDictionary],
    },
    edit: {
      path: "/contextenotedefrais/editer/:id",
      root: "/contextenotedefrais/editer",
      name: "contextenotedefrais.edit",
      roles: [RolesApplicationEnum.EditDictionary],
    },
    add: {
      path: "/contextenotedefrais/ajouter",
      name: "contextenotedefrais.add",
      roles: [RolesApplicationEnum.EditDictionary],
    },
  },

  /** convention collective */
  collectiveAgreement: {
    view: {
      path: "/conventionscollectives/consulter/:id",
      root: "/conventionscollectives/consulter",
      name: "conventionscollectives.view",
      roles: [RolesApplicationEnum.ReadUser],
    },
    table: {
      path: "/conventionscollectives",
      name: "conventionscollectives.table",
      roles: [RolesApplicationEnum.ReadUser],
    },
    edit: {
      path: "/conventionscollectives/editer/:id",
      root: "/conventionscollectives/editer",
      name: "conventionscollectives.edit",
      roles: [RolesApplicationEnum.EditUser],
    },
    add: {
      path: "/conventionscollectives/ajouter",
      name: "conventionscollectives.add",
      roles: [RolesApplicationEnum.EditUser],
    },
  },

  /**manipulation des établissements */
  etablishments: {
    edit: {
      path: "/etablissementsdirection/editer/:id",
      root: "/etablissementsdirection/editer",
      name: "etablissementsdirection.edit",
      roles: [RolesApplicationEnum.EditEstablishmentDirection],
    },
    add: {
      path: "/etablissementsdirection/ajouter",
      name: "etablissementsdirection.add",
      roles: [RolesApplicationEnum.ReadEstablishmentDirection],
    },
    table: {
      path: "/etablissementsdirection",
      name: "etablissementsdirection.table",
      roles: [RolesApplicationEnum.ReadEstablishmentDirection],
    },
    view: {
      path: "/etablissementsdirection/consulter/:id",
      root: "/etablissementsdirection/consulter",
      name: "etablissementsdirection.view",
      roles: [RolesApplicationEnum.DetailEstablishmentDirection],
    },
  },
  /**manipulation des status des établissements */
  establishmentsStatus: {
    edit: {
      path: "/etablissementsstatus/editer/:id",
      root: "/etablissementsstatus/editer",
      name: "etablissementsstatus.edit",
      roles: [RolesApplicationEnum.EditEstablishment],
    },
    add: {
      path: "/etablissementsstatus/ajouter",
      name: "etablissementsstatus.add",
      roles: [RolesApplicationEnum.EditEstablishment],
    },
    table: {
      path: "/etablissementsstatus",
      name: "etablissementsstatus.table",
      roles: [RolesApplicationEnum.ReadEstablishment],
    },
    view: {
      path: "/etablissementsstatus/consulter/:id",
      root: "/etablissementsstatus/consulter",
      name: "etablissementsstatus.view",
      roles: [RolesApplicationEnum.ReadEstablishment],
    },
  },
  /**manipulation des status des établissements */
  establishmentsJuris: {
    edit: {
      path: "/etablissementsjuris/editer/:id",
      root: "/etablissementsjuris/editer",
      name: "etablissementsjuris.edit",
      roles: [RolesApplicationEnum.EditEstablishmentJuris],
    },
    add: {
      path: "/etablissementsjuris/ajouter",
      name: "etablissementsjuris.add",
      roles: [RolesApplicationEnum.EditEstablishmentJuris],
    },
    table: {
      path: "/etablissementsjuris",
      name: "etablissementsjuris.table",
      roles: [RolesApplicationEnum.ReadEstablishmentJuris],
    },
    view: {
      path: "/etablissementsjuris/consulter/:id",
      root: "/etablissementsjuris/consulter",
      name: "etablissementsjuris.view",
      roles: [RolesApplicationEnum.ReadEstablishmentJuris],
    },
  },
  /**manipulation des super établissements */
  superEstablishments: {
    edit: {
      path: "/superetablissements/editer/:id",
      root: "/superetablissements/editer",
      name: "superetablissements.edit",
      roles: [RolesApplicationEnum.EditEstablishment],
    },
    add: {
      path: "/superetablissements/ajouter",
      name: "superetablissements.add",
      roles: [RolesApplicationEnum.EditEstablishment],
    },
    table: {
      path: "/superetablissements",
      name: "superetablissements.table",
      roles: [RolesApplicationEnum.ReadEstablishment],
    },
    view: {
      path: "/superetablissements/consulter/:id",
      root: "/superetablissements/consulter",
      name: "superetablissements.view",
      roles: [RolesApplicationEnum.ReadEstablishment],
    },
  },

  /**manipulation des référents/fonctions/établissement */
  referentFunction: {
    edit: {
      path: "/referentfonction/editer/:id",
      root: "/referentfonction/editer",
      name: "referentfonction.edit",
      roles: [RolesApplicationEnum.EditEtablishmentReferentFunction],
    },
    table: {
      path: "/referentfonction",
      name: "referentfonction.table",
      roles: [RolesApplicationEnum.ReadEtablishmentReferentFunction],
    },
    view: {
      path: "/referentfonction/consulter/:id",
      root: "/referentfonction/consulter",
      name: "referentfonction.view",
      roles: [RolesApplicationEnum.DetailEtablishmentReferentFunction],
    },
  },

  /**manipulation des fonctions */
  functions: {
    edit: {
      path: "/fonctions/editer/:id",
      root: "/fonctions/editer",
      name: "fonctions.edit",
      roles: [RolesApplicationEnum.EditServiceSFR],
    },
    add: {
      path: "/fonctions/ajouter",
      name: "fonctions.add",
      title: "Ajouter une fonction",
      roles: [RolesApplicationEnum.EditServiceSFR],
    },
    view: {
      path: "/fonctions/:id",
      root: "/fonctions",
      name: "fonctions.view",
      roles: [RolesApplicationEnum.ReadServiceSFR],
    },
    table: {
      path: "/fonctions",
      name: "fonctions.table",
      roles: [RolesApplicationEnum.ReadServiceSFR],
    },
  },

  /** métiers */
  metiers: {
    view: {
      path: "/metiers/consulter/:id",
      root: "/metiers/consulter",
      name: "metiers.view",
      roles: [RolesApplicationEnum.ReadServiceSFR],
    },
    table: {
      path: "/metiers",
      name: "metiers.table",
      roles: [RolesApplicationEnum.ReadServiceSFR],
    },
    edit: {
      path: "/metiers/editer/:id",
      root: "/metiers/editer",
      name: "metiers.edit",
      roles: [RolesApplicationEnum.EditServiceSFR],
    },
    add: {
      path: "/metiers/ajouter",
      name: "metiers.add",
      roles: [RolesApplicationEnum.EditServiceSFR],
    },
  },

  /** motifs CDD */
  cddreasons: {
    view: {
      path: "/motifscdd/consulter/:id",
      root: "/motifscdd/consulter",
      name: "motifscdd.view",
      roles: [RolesApplicationEnum.ReadDictionary],
    },
    table: {
      path: "/motifscdd",
      name: "motifscdd.table",
      roles: [RolesApplicationEnum.ReadDictionary],
    },
    edit: {
      path: "/motifscdd/editer/:id",
      root: "/motifscdd/editer",
      name: "motifscdd.edit",
      roles: [RolesApplicationEnum.EditDictionary],
    },
    add: {
      path: "/motifscdd/ajouter",
      name: "motifscdd.add",
      roles: [RolesApplicationEnum.EditDictionary],
    },
  },

  /** motifs congés */
  vacationreasons: {
    view: {
      path: "/motifsconges/consulter/:id",
      root: "/motifsconges/consulter",
      name: "motifsconges.view",
      roles: [RolesApplicationEnum.ReadDictionary],
    },
    table: {
      path: "/motifsconges",
      name: "motifsconges.table",
      roles: [RolesApplicationEnum.ReadDictionary],
    },
    edit: {
      path: "/motifsconges/editer/:id",
      root: "/motifsconges/editer",
      name: "motifsconges.edit",
      roles: [RolesApplicationEnum.EditDictionary],
    },
    add: {
      path: "/motifsconges/ajouter",
      name: "motifsconges.add",
      roles: [RolesApplicationEnum.EditDictionary],
    },
  },

  /** Manipulation des motifs de maladie */
  diseasesReason: {
    view: {
      path: "/motifsmaladie/consulter/:id",
      root: "/motifsmaladie/consulter",
      name: "motifsmaladie.view",
      roles: [RolesApplicationEnum.ReadDictionary],
    },
    table: {
      path: "/motifsmaladie",
      name: "motifsmaladie.table",
      roles: [RolesApplicationEnum.ReadDictionary],
    },
    add: {
      path: "/motifsmaladie/ajouter",
      name: "motifsmaladie.add",
      roles: [RolesApplicationEnum.EditDictionary],
    },
    edit: {
      path: "/motifsmaladie/editer/:id",
      root: "/motifsmaladie/editer",
      name: "motifsmaladie.edit",
      roles: [RolesApplicationEnum.EditDictionary],
    },
  },

  /** Manipulation des motifs de temps de travail <24h */
  worksDurationReason: {
    view: {
      path: "/motifstempsdetravail/consulter/:id",
      root: "/motifstempsdetravail/consulter",
      name: "motifstempsdetravail.view",
      roles: [RolesApplicationEnum.ReadDictionary],
    },
    table: {
      path: "/motifstempsdetravail",
      name: "motifstempsdetravail.table",
      roles: [RolesApplicationEnum.ReadDictionary],
    },
    add: {
      path: "/motifstempsdetravail/ajouter",
      name: "motifstempsdetravail.add",
      roles: [RolesApplicationEnum.EditDictionary],
    },
    edit: {
      path: "/motifstempsdetravail/editer/:id",
      root: "/motifstempsdetravail/editer",
      name: "motifstempsdetravail.edit",
      roles: [RolesApplicationEnum.EditDictionary],
    },
  },

  /** Manipulation des natures de remboursements */
  refundTypes: {
    view: {
      path: "/naturesremboursement/consulter/:id",
      root: "/naturesremboursement/consulter",
      name: "naturesremboursement.view",
      roles: [RolesApplicationEnum.ReadDictionary],
    },
    table: {
      path: "/naturesremboursement",
      name: "naturesremboursement.table",
      roles: [RolesApplicationEnum.ReadDictionary],
    },
    add: {
      path: "/naturesremboursement/ajouter",
      name: "naturesremboursement.add",
      roles: [RolesApplicationEnum.EditDictionary],
    },
    edit: {
      path: "/naturesremboursement/editer/:id",
      root: "/naturesremboursement/editer",
      name: "naturesremboursement.edit",
      roles: [RolesApplicationEnum.EditDictionary],
    },
  },

  /** Manipulation des organisation des temps de travail */
  workingTimeOrganization: {
    view: {
      path: "/organisationtempsdetravail/consulter/:id",
      root: "/organisationtempsdetravail/consulter",
      name: "organisationtempsdetravail.view",
      roles: [RolesApplicationEnum.ReadDictionary],
    },
    table: {
      path: "/organisationtempsdetravail",
      name: "organisationtempsdetravail.table",
      roles: [RolesApplicationEnum.ReadDictionary],
    },
    add: {
      path: "/organisationtempsdetravail/ajouter",
      name: "organisationtempsdetravail.add",
      roles: [RolesApplicationEnum.EditDictionary],
    },
    edit: {
      path: "/organisationtempsdetravail/editer/:id",
      root: "/organisationtempsdetravail/editer",
      name: "organisationtempsdetravail.edit",
      roles: [RolesApplicationEnum.EditDictionary],
    },
  },

  /** Manipulation des origines de prospect */
  prospectOrigins: {
    view: {
      path: "/originesprospect/consulter/:id",
      root: "/originesprospect/consulter",
      name: "originesprospect.view",
      roles: [RolesApplicationEnum.ReadDictionary],
    },
    table: {
      path: "/originesprospect",
      name: "originesprospect.table",
      roles: [RolesApplicationEnum.ReadDictionary],
    },
    add: {
      path: "/originesprospect/ajouter",
      name: "originesprospect.add",
      roles: [RolesApplicationEnum.EditDictionary],
    },
    edit: {
      path: "/originesprospect/editer/:id",
      root: "/originesprospect/editer",
      name: "originesprospect.edit",
      roles: [RolesApplicationEnum.EditDictionary],
    },
  },

  /** Manipulation des rubriques */
  rubrics: {
    view: {
      path: "/rubriques/consulter/:id",
      root: "/rubriques/consulter",
      name: "rubriques.view",
      roles: [RolesApplicationEnum.ReadServiceSFR],
    },
    table: {
      path: "/rubriques",
      name: "rubriques.table",
      roles: [RolesApplicationEnum.ReadServiceSFR],
    },
    add: {
      path: "/rubriques/ajouter",
      name: "rubriques.add",
      roles: [RolesApplicationEnum.EditServiceSFR],
    },
    edit: {
      path: "/rubriques/editer/:id",
      root: "/rubriques/editer",
      name: "rubriques.edit",
      roles: [RolesApplicationEnum.EditServiceSFR],
    },
  },

  /** Manipulation des rubriques globales */
  globalRubrics: {
    view: {
      path: "/rubriquesglobales/consulter/:id",
      root: "/rubriquesglobales/consulter",
      name: "rubriquesglobales.view",
      roles: [RolesApplicationEnum.ReadServiceSFR],
    },
    table: {
      path: "/rubriquesglobales",
      name: "rubriquesglobales.table",
      roles: [RolesApplicationEnum.ReadServiceSFR],
    },
    add: {
      path: "/rubriquesglobales/ajouter",
      name: "rubriquesglobales.add",
      roles: [RolesApplicationEnum.EditServiceSFR],
    },
    edit: {
      path: "/rubriquesglobales/editer/:id",
      root: "/rubriquesglobales/editer",
      name: "rubriquesglobales.edit",
      roles: [RolesApplicationEnum.EditServiceSFR],
    },
  },

  /**manipulation des services */
  services: {
    view: {
      path: "/services/consulter/:id",
      root: "/services/consulter",
      name: "services.view",
      roles: [RolesApplicationEnum.ReadServiceSFR],
    },
    table: {
      path: "/services",
      name: "services.table",
      roles: [RolesApplicationEnum.ReadServiceSFR],
    },
    add: {
      path: "/services/ajouter",
      name: "services.add",
      roles: [RolesApplicationEnum.EditServiceSFR],
    },
    edit: {
      path: "/services/editer/:id",
      root: "/services/editer",
      name: "services.edit",
      roles: [RolesApplicationEnum.EditServiceSFR],
    },
  },

  /** Manipulation des sexes */
  genders: {
    view: {
      path: "/sexes/consulter/:id",
      root: "/sexes/consulter",
      name: "sexes.view",
      roles: [RolesApplicationEnum.ReadUser],
    },
    table: {
      path: "/sexes",
      name: "sexes.table",
      roles: [RolesApplicationEnum.ReadUser],
    },
    add: {
      path: "/sexes/ajouter",
      name: "sexes.add",
      roles: [RolesApplicationEnum.EditUser],
    },
    edit: {
      path: "/sexes/editer/:id",
      root: "/sexes/editer",
      name: "sexes.edit",
      roles: [RolesApplicationEnum.EditUser],
    },
  },

  /** Manipulation des sous-activitées */
  subActivities: {
    view: {
      path: "/sousactivite/consulter/:id",
      root: "/sousactivite/consulter",
      name: "sousactivite.view",
      roles: [RolesApplicationEnum.ReadServiceSFR],
    },
    table: {
      path: "/sousactivite",
      name: "sousactivite.table",
      roles: [RolesApplicationEnum.ReadServiceSFR],
    },
    add: {
      path: "/sousactivite/ajouter",
      name: "sousactivite.add",
      roles: [RolesApplicationEnum.EditServiceSFR],
    },
    edit: {
      path: "/sousactivite/editer/:id",
      root: "/sousactivite/editer",
      name: "sousactivite.edit",
      roles: [RolesApplicationEnum.EditServiceSFR],
    },
  },

  /** Manipulation des structures juridique */
  legalStructure: {
    view: {
      path: "/structurejuridique/consulter/:id",
      root: "/structurejuridique/consulter",
      name: "structurejuridique.view",
      roles: [RolesApplicationEnum.DetailLegalStructure],
    },
    table: {
      path: "/structurejuridique",
      name: "structurejuridique.table",
      roles: [RolesApplicationEnum.ReadJuridique],
    },
    add: {
      path: "/structurejuridique/ajouter",
      name: "structurejuridique.add",
      roles: [RolesApplicationEnum.EditJuridique],
    },
    edit: {
      path: "/structurejuridique/editer/:id",
      root: "/structurejuridique/editer",
      name: "structurejuridique.edit",
      roles: [RolesApplicationEnum.EditLegalStructure],
    },
  },

  /** Manipulation des natures de structure juridique */
  legalStructureTypes: {
    view: {
      path: "/naturestructurejuridique/consulter/:id",
      root: "/naturestructurejuridique/consulter",
      name: "naturestructurejuridique.view",
      roles: [RolesApplicationEnum.ReadJuridique],
    },
    table: {
      path: "/naturestructurejuridique",
      name: "naturestructurejuridique.table",
      roles: [RolesApplicationEnum.ReadJuridique],
    },
    add: {
      path: "/naturestructurejuridique/ajouter",
      name: "naturestructurejuridique.add",
      roles: [RolesApplicationEnum.EditJuridique],
    },
    edit: {
      path: "/naturestructurejuridique/editer/:id",
      root: "/naturestructurejuridique/editer",
      name: "naturestructurejuridique.edit",
      roles: [RolesApplicationEnum.EditJuridique],
    },
  },

  /** Structure juridiques / statut */
  legalStructureStatus: {
    view: {
      path: "/statutstructurejuridique/consulter/:id",
      root: "/statutstructurejuridique/consulter",
      name: "statutstructurejuridique.view",
      roles: [RolesApplicationEnum.ReadJuridique],
    },
    table: {
      path: "/statutstructurejuridique",
      name: "statutstructurejuridique.table",
      roles: [RolesApplicationEnum.ReadJuridique],
    },
    add: {
      path: "/statutstructurejuridique/ajouter",
      name: "statutstructurejuridique.add",
      roles: [RolesApplicationEnum.EditJuridique],
    },
    edit: {
      path: "/statutstructurejuridique/editer/:id",
      root: "/statutstructurejuridique/editer",
      name: "statutstructurejuridique.edit",
      roles: [RolesApplicationEnum.EditJuridique],
    },
  },

  /** Structure juridiques / repésentant légal */
  legalRepresentativeStatus: {
    view: {
      path: "/representantlegalstructurejuridique/consulter/:id",
      root: "/representantlegalstructurejuridique/consulter",
      name: "representantlegalstructurejuridique.view",
      roles: [RolesApplicationEnum.ReadJuridique],
    },
    table: {
      path: "/representantlegalstructurejuridique",
      name: "representantlegalstructurejuridique.table",
      roles: [RolesApplicationEnum.ReadJuridique],
    },
    add: {
      path: "/representantlegalstructurejuridique/ajouter",
      name: "representantlegalstructurejuridique.add",
      roles: [RolesApplicationEnum.EditJuridique],
    },
    edit: {
      path: "/representantlegalstructurejuridique/editer/:id",
      root: "/representantlegalstructurejuridique/editer",
      name: "representantlegalstructurejuridique.edit",
      roles: [RolesApplicationEnum.EditJuridique],
    },
  },

  /** Structure juridiques / types d'évènement */
  legalStructureEventTypes: {
    view: {
      path: "/typeevenementstructurejuridique/consulter/:id",
      root: "/typeevenementstructurejuridique/consulter",
      name: "typeevenementstructurejuridique.view",
      roles: [RolesApplicationEnum.ReadJuridique],
    },
    table: {
      path: "/typeevenementstructurejuridique",
      name: "typeevenementstructurejuridique.table",
      roles: [RolesApplicationEnum.ReadJuridique],
    },
    add: {
      path: "/typeevenementstructurejuridique/ajouter",
      name: "typeevenementstructurejuridique.add",
      roles: [RolesApplicationEnum.EditJuridique],
    },
    edit: {
      path: "/typeevenementstructurejuridique/editer/:id",
      root: "/typeevenementstructurejuridique/editer",
      name: "typeevenementstructurejuridique.edit",
      roles: [RolesApplicationEnum.EditJuridique],
    },
  },

  /** Structure juridiques / super structure juridique */
  superLegalStructure: {
    view: {
      path: "/superstructurejuridique/consulter/:id",
      root: "/superstructurejuridique/consulter",
      name: "superstructurejuridique.view",
      roles: [RolesApplicationEnum.ReadJuridique],
    },
    table: {
      path: "/superstructurejuridique",
      name: "superstructurejuridique.table",
      roles: [RolesApplicationEnum.ReadJuridique],
    },
    add: {
      path: "/superstructurejuridique/ajouter",
      name: "superstructurejuridique.add",
      roles: [RolesApplicationEnum.EditJuridique],
    },
    edit: {
      path: "/superstructurejuridique/editer/:id",
      root: "/superstructurejuridique/editer",
      name: "superstructurejuridique.edit",
      roles: [RolesApplicationEnum.EditJuridique],
    },
  },

  /** Manipulation des temps de travail */
  worksDuration: {
    view: {
      path: "/tempsdetravail/consulter/:id",
      root: "/tempsdetravail/consulter",
      name: "tempsdetravail.view",
      roles: [RolesApplicationEnum.ReadUser],
    },
    table: {
      path: "/tempsdetravail",
      name: "tempsdetravail.table",
      roles: [RolesApplicationEnum.ReadUser],
    },
    add: {
      path: "/tempsdetravail/ajouter",
      name: "tempsdetravail.add",
      roles: [RolesApplicationEnum.EditUser],
    },
    edit: {
      path: "/tempsdetravail/editer/:id",
      root: "/tempsdetravail/editer",
      name: "tempsdetravail.edit",
      roles: [RolesApplicationEnum.EditUser],
    },
  },

  /** les titres employés */
  employeetitles: {
    view: {
      path: "/titrescollaborateurs/consulter/:id",
      root: "/titrescollaborateurs/consulter",
      name: "titrescollaborateurs.view",
      roles: [RolesApplicationEnum.ReadServiceSFR],
    },
    table: {
      path: "/titrescollaborateurs",
      name: "titrescollaborateurs.table",
      roles: [RolesApplicationEnum.ReadServiceSFR],
    },
    add: {
      path: "/titrescollaborateurs/ajouter",
      name: "titrescollaborateurs.add",
      roles: [RolesApplicationEnum.EditServiceSFR],
    },
    edit: {
      path: "/titrescollaborateurs/editer/:id",
      root: "/titrescollaborateurs/editer",
      name: "titrescollaborateurs.edit",
      roles: [RolesApplicationEnum.EditServiceSFR],
    },
  },

  /** les types de contrat externe */
  externalContractTypes: {
    view: {
      path: "/typescontratexterne/consulter/:id",
      root: "/typescontratexterne/consulter",
      name: "typescontratexterne.view",
      roles: [RolesApplicationEnum.ReadJuridique],
    },
    table: {
      path: "/typescontratexterne",
      name: "typescontratexterne.table",
      roles: [RolesApplicationEnum.ReadJuridique],
    },
    edit: {
      path: "/typescontratexterne/editer/:id",
      root: "/typescontratexterne/editer",
      name: "typescontratexterne.edit",
      roles: [RolesApplicationEnum.EditJuridique],
    },
    add: {
      path: "/typescontratexterne/ajouter",
      name: "typescontratexterne.add",
      roles: [RolesApplicationEnum.EditJuridique],
    },
  },

  /** les types d'utilisateurs */
  usertypes: {
    view: {
      path: "/typescollaborateurs/consulter/:id",
      root: "/typescollaborateurs/consulter",
      name: "typescollaborateurs.view",
      roles: [RolesApplicationEnum.ReadUser],
    },
    table: {
      path: "/typescollaborateurs",
      name: "typescollaborateurs.table",
      roles: [RolesApplicationEnum.ReadUser],
    },
    add: {
      path: "/typescollaborateurs/ajouter",
      name: "typescollaborateurs.add",
      roles: [RolesApplicationEnum.EditUser],
    },
    edit: {
      path: "/typescollaborateurs/editer/:id",
      root: "/typescollaborateurs/editer",
      name: "typescollaborateurs.edit",
      roles: [RolesApplicationEnum.EditUser],
    },
  },

  /** les marques/domaines */
  domainBrands: {
    view: {
      path: "/marquesmetiers-nomsdomaine/consulter/:id",
      root: "/marquesmetiers-nomsdomaine/consulter",
      name: "marquesmetiers-nomsdomaine.view",
      roles: [RolesApplicationEnum.ReadServiceSFR],
    },
    table: {
      path: "/marquesmetiers-nomsdomaine",
      name: "marquesmetiers-nomsdomaine.table",
      roles: [RolesApplicationEnum.ReadServiceSFR],
    },
    add: {
      path: "/marquesmetiers-nomsdomaine/ajouter",
      name: "marquesmetiers-nomsdomaine.add",
      roles: [RolesApplicationEnum.EditServiceSFR],
    },
    edit: {
      path: "/marquesmetiers-nomsdomaine/editer/:id",
      root: "/marquesmetiers-nomsdomaine/editer",
      name: "marquesmetiers-nomsdomaine.edit",
      roles: [RolesApplicationEnum.EditServiceSFR],
    },
  },
  /** les spécialités médicales */
  medicalSpecialties: {
    view: {
      path: "/specialitesmedicales/consulter/:id",
      root: "/specialitesmedicales/consulter",
      name: "specialitesmedicales.view",
      roles: [RolesApplicationEnum.ReadPracticioner],
    },
    table: {
      path: "/specialitesmedicales",
      name: "specialitesmedicales.table",
      roles: [RolesApplicationEnum.ReadPracticioner],
    },
    add: {
      path: "/specialitesmedicales/ajouter",
      name: "specialitesmedicales.add",
      roles: [RolesApplicationEnum.EditPracticioner],
    },
    edit: {
      path: "/specialitesmedicales/editer/:id",
      root: "/specialitesmedicales/editer",
      name: "specialitesmedicales.edit",
      roles: [RolesApplicationEnum.EditPracticioner],
    },
  },
  /** les niveaux d'urgences pour le ticketing */
  emergencyLevelTicketing: {
    view: {
      path: "/niveauxurgenceticketing/consulter/:id",
      root: "/niveauxurgenceticketing/consulter",
      name: "niveauxurgenceticketing.view",
      roles: [RolesApplicationEnum.ReadConf],
    },
    table: {
      path: "/niveauxurgenceticketing",
      name: "niveauxurgenceticketing.table",
      roles: [RolesApplicationEnum.ReadConf],
    },
    add: {
      path: "/niveauxurgenceticketing/ajouter",
      name: "niveauxurgenceticketing.add",
      roles: [RolesApplicationEnum.EditConf],
    },
    edit: {
      path: "/niveauxurgenceticketing/editer/:id",
      root: "/niveauxurgenceticketing/editer",
      name: "niveauxurgenceticketing.edit",
      roles: [RolesApplicationEnum.EditConf],
    },
  },
  /** les niveaux d'urgences pour les transmissions */
  emergencyLevelTransmission: {
    view: {
      path: "/niveauxurgencetransmission/consulter/:id",
      root: "/niveauxurgencetransmission/consulter",
      name: "niveauxurgencetransmission.view",
      roles: [RolesApplicationEnum.ReadConf],
    },
    table: {
      path: "/niveauxurgencetransmission",
      name: "niveauxurgencetransmission.table",
      roles: [RolesApplicationEnum.ReadConf],
    },
    add: {
      path: "/niveauxurgencetransmission/ajouter",
      name: "niveauxurgencetransmission.add",
      roles: [RolesApplicationEnum.EditConf],
    },
    edit: {
      path: "/niveauxurgencetransmission/editer/:id",
      root: "/niveauxurgencetransmission/editer",
      name: "niveauxurgencetransmission.edit",
      roles: [RolesApplicationEnum.EditConf],
    },
  },
  /** les perimetres dans le ticketing */
  ticketingPerimeter: {
    view: {
      path: "/ticketingperimetre/consulter/:id",
      root: "/ticketingperimetre/consulter",
      name: "ticketingperimetre.view",
      roles: [RolesApplicationEnum.ReadConf],
    },
    table: {
      path: "/ticketingperimetre",
      name: "ticketingperimetre.table",
      roles: [RolesApplicationEnum.ReadConf],
    },
    add: {
      path: "/ticketingperimetre/ajouter",
      name: "ticketingperimetre.add",
      roles: [RolesApplicationEnum.EditConf],
    },
    edit: {
      path: "/ticketingperimetre/editer/:id",
      root: "/ticketingperimetre/editer",
      name: "ticketingperimetre.edit",
      roles: [RolesApplicationEnum.EditConf],
    },
  },
  /** les destinataires (récipient type) dans le ticketing */
  ticketingRecipientType: {
    view: {
      path: "/ticketingdestinataires/consulter/:id",
      root: "/ticketingdestinataires/consulter",
      name: "ticketingdestinataires.view",
      roles: [RolesApplicationEnum.ReadConf],
    },
    table: {
      path: "/ticketingdestinataires",
      name: "ticketingdestinataires.table",
      roles: [RolesApplicationEnum.ReadConf],
    },
    add: {
      path: "/ticketingdestinataires/ajouter",
      name: "ticketingdestinataires.add",
      roles: [RolesApplicationEnum.EditConf],
    },
    edit: {
      path: "/ticketingdestinataires/editer/:id",
      root: "/ticketingdestinataires/editer",
      name: "ticketingdestinataires.edit",
      roles: [RolesApplicationEnum.EditConf],
    },
  },
  /** les status dans le ticketing */
  ticketingStatus: {
    view: {
      path: "/ticketingstatut/consulter/:id",
      root: "/ticketingstatut/consulter",
      name: "ticketingstatut.view",
      roles: [RolesApplicationEnum.ReadConf],
    },
    table: {
      path: "/ticketingstatut",
      name: "ticketingstatut.table",
      roles: [RolesApplicationEnum.ReadConf],
    },
    add: {
      path: "/ticketingstatut/ajouter",
      name: "ticketingstatut.add",
      roles: [RolesApplicationEnum.EditConf],
    },
    edit: {
      path: "/ticketingstatut/editer/:id",
      root: "/ticketingstatut/editer",
      name: "ticketingstatut.edit",
      roles: [RolesApplicationEnum.EditConf],
    },
  },
  /** les status spécifiques dans le ticketing */
  ticketingSpecificStatus: {
    view: {
      path: "/ticketingspecificstatut/consulter/:id",
      root: "/ticketingspecificstatut/consulter",
      name: "ticketingspecificstatut.view",
      roles: [RolesApplicationEnum.ReadTicketingSpecificStatus],
    },
    table: {
      path: "/ticketingspecificstatut",
      name: "ticketingspecificstatut.table",
      roles: [RolesApplicationEnum.ReadTicketingSpecificStatus],
    },
    add: {
      path: "/ticketingspecificstatut/ajouter",
      name: "ticketingspecificstatut.add",
      roles: [RolesApplicationEnum.EditTicketingSpecificStatus],
    },
    edit: {
      path: "/ticketingspecificstatut/editer/:id",
      root: "/ticketingspecificstatut/editer",
      name: "ticketingspecificstatut.edit",
      roles: [RolesApplicationEnum.EditTicketingSpecificStatus],
    },
  },
  /** les objects et les sous-objects dans le ticketing */
  ticketingObjectSubobjects: {
    view: {
      path: "/ticketingobjetsousobjet/consulter/:id",
      root: "/ticketingobjetsousobjet/consulter",
      name: "ticketingobjetsousobjet.view",
      roles: [RolesApplicationEnum.ReadTicketingObjectSubObject],
    },
    table: {
      path: "/ticketingobjetsousobjet",
      name: "ticketingobjetsousobjet.table",
      roles: [RolesApplicationEnum.ReadTicketingObjectSubObject],
    },
    add: {
      path: "/ticketingobjetsousobjet/ajouter",
      name: "ticketingobjetsousobjet.add",
      roles: [RolesApplicationEnum.EditTicketingObjectSubObject],
    },
    edit: {
      path: "/ticketingobjetsousobjet/editer/:id",
      root: "/ticketingobjetsousobjet/editer",
      name: "ticketingobjetsousobjet.edit",
      roles: [RolesApplicationEnum.EditTicketingObjectSubObject],
    },
  },
  /** les applications */
  applications: {
    view: {
      path: "/applications/consulter/:id",
      root: "/applications/consulter",
      name: "applications.view",
      roles: [RolesApplicationEnum.ReadApplications],
    },
    table: {
      path: "/applications",
      name: "applications.table",
      roles: [RolesApplicationEnum.ReadDictionary],
    },
    add: {
      path: "/applications/ajouter",
      name: "applications.add",
      roles: [RolesApplicationEnum.EditApplications],
    },
    edit: {
      path: "/applications/editer/:id",
      root: "/applications/editer",
      name: "applications.edit",
      roles: [RolesApplicationEnum.EditApplications],
    },
  },
  /** les natures de relation de travail */
  workRelationshipTypes: {
    view: {
      path: "/naturerelationtravail/consulter/:id",
      root: "/naturerelationtravail/consulter",
      name: "naturerelationtravail.view",
      roles: [RolesApplicationEnum.ReadUser],
    },
    table: {
      path: "/naturerelationtravail",
      name: "naturerelationtravail.table",
      roles: [RolesApplicationEnum.ReadUser],
    },
    add: {
      path: "/naturerelationtravail/ajouter",
      name: "naturerelationtravail.add",
      roles: [RolesApplicationEnum.EditUser],
    },
    edit: {
      path: "/naturerelationtravail/editer/:id",
      root: "/naturerelationtravail/editer",
      name: "naturerelationtravail.edit",
      roles: [RolesApplicationEnum.EditUser],
    },
  },
  /** les types de rémunération */
  remunerationTypes: {
    view: {
      path: "/typeremuneration/consulter/:id",
      root: "/typeremuneration/consulter",
      name: "typeremuneration.view",
      roles: [RolesApplicationEnum.ReadUser],
    },
    table: {
      path: "/typeremuneration",
      name: "typeremuneration.table",
      roles: [RolesApplicationEnum.ReadUser],
    },
    add: {
      path: "/typeremuneration/ajouter",
      name: "typeremuneration.add",
      roles: [RolesApplicationEnum.EditUser],
    },
    edit: {
      path: "/typeremuneration/editer/:id",
      root: "/typeremuneration/editer",
      name: "typeremuneration.edit",
      roles: [RolesApplicationEnum.EditUser],
    },
  },
  /** les motifs de recrutement */
  recruitingReason: {
    view: {
      path: "/motifrecrutement/consulter/:id",
      root: "/motifrecrutement/consulter",
      name: "motifrecrutement.view",
      roles: [RolesApplicationEnum.ReadUser],
    },
    table: {
      path: "/motifrecrutement",
      name: "motifrecrutement.table",
      roles: [RolesApplicationEnum.ReadUser],
    },
    add: {
      path: "/motifrecrutement/ajouter",
      name: "motifrecrutement.add",
      roles: [RolesApplicationEnum.EditUser],
    },
    edit: {
      path: "/motifrecrutement/editer/:id",
      root: "/motifrecrutement/editer",
      name: "motifrecrutement.edit",
      roles: [RolesApplicationEnum.EditUser],
    },
  },
  /** les complément accès poste (point requis annonce) */
  jobAdRequiredPoint: {
    view: {
      path: "/complementposte/consulter/:id",
      root: "/complementaccesposte/consulter",
      name: "complementaccesposte.view",
      roles: [RolesApplicationEnum.ReadUser],
    },
    table: {
      path: "/complementaccesposte",
      name: "complementaccesposte.table",
      roles: [RolesApplicationEnum.ReadUser],
    },
    add: {
      path: "/complementaccesposte/ajouter",
      name: "complementaccesposte.add",
      roles: [RolesApplicationEnum.EditUser],
    },
    edit: {
      path: "/complementaccesposte/editer/:id",
      root: "/complementaccesposte/editer",
      name: "complementaccesposte.edit",
      roles: [RolesApplicationEnum.EditUser],
    },
  },
  /** les intitulés de poste */
  jobTitle: {
    view: {
      path: "/intituleposte/consulter/:id",
      root: "/intituleposte/consulter",
      name: "intituleposte.view",
      roles: [RolesApplicationEnum.ReadDictionary],
    },
    table: {
      path: "/intituleposte",
      name: "intituleposte.table",
      roles: [RolesApplicationEnum.ReadDictionary],
    },
    add: {
      path: "/intituleposte/ajouter",
      name: "intituleposte.add",
      roles: [RolesApplicationEnum.EditDictionary],
    },
    edit: {
      path: "/intituleposte/editer/:id",
      root: "/intituleposte/editer",
      name: "intituleposte.edit",
      roles: [RolesApplicationEnum.EditDictionary],
    },
  },
  /** les grands domaines ROME */
  largeDomainRome: {
    // view: {
    //   path: "/grandomainerome/consulter/:id",
    //   root: "/grandomainerome/consulter",
    //   name: "grandomainerome.view",
    // },
    table: {
      path: "/grandomainerome",
      name: "grandomainerome.table",
      roles: [RolesApplicationEnum.ReadRomeHightDomain],
    },
    // add: {
    //   path: "/grandomainerome/ajouter",
    //   name: "grandomainerome.add",
    // },
    // edit: {
    //   path: "/grandomainerome/editer/:id",
    //   root: "/grandomainerome/editer",
    //   name: "grandomainerome.edit",
    // },
  },

  /**Configuration de l'application transmission */
  transmission: {
    ecosystems: {
      view: {
        path: "/transmissions/ecosystemes/consulter/:id",
        root: "/transmissions/ecosystemes/consulter",
        name: "transmissions/ecosystemes.view",
        roles: [RolesApplicationEnum.ReadConf],
      },
      table: {
        path: "/transmissions/ecosystemes",
        name: "transmissions/ecosystemes.table",
        roles: [RolesApplicationEnum.ReadConf],
      },
      add: {
        path: "/transmissions/ecosystemes/ajouter",
        name: "transmissions/ecosystemes.add",
        roles: [RolesApplicationEnum.EditConf],
      },
      edit: {
        path: "/transmissions/ecosystemes/editer/:id",
        root: "/transmissions/ecosystemes/editer",
        name: "transmissions/ecosystemes.edit",
        roles: [RolesApplicationEnum.EditConf],
      },
    },
    mediums: {
      view: {
        path: "/transmissions/mediums/consulter/:id",
        root: "/transmissions/mediums/consulter",
        name: "transmissions/mediums.view",
        roles: [RolesApplicationEnum.ReadConf],
      },
      table: {
        path: "/transmissions/mediums",
        name: "transmissions/mediums.table",
        roles: [RolesApplicationEnum.ReadConf],
      },
      add: {
        path: "/transmissions/mediums/ajouter",
        name: "transmissions/mediums.add",
        roles: [RolesApplicationEnum.EditConf],
      },
      edit: {
        path: "/transmissions/mediums/editer/:id",
        root: "/transmissions/mediums/editer",
        name: "transmissions/mediums.edit",
        roles: [RolesApplicationEnum.EditConf],
      },
    },
    otherrecipienttypes: {
      view: {
        path: "/transmissions/destinataires/consulter/:id",
        root: "/transmissions/destinataires/consulter",
        name: "transmissions/destinataires.view",
        roles: [RolesApplicationEnum.ReadConf],
      },
      table: {
        path: "/transmissions/destinataires",
        name: "transmissions/destinataires.table",
        roles: [RolesApplicationEnum.ReadConf],
      },
      add: {
        path: "/transmissions/destinataires/ajouter",
        name: "transmissions/destinataires.add",
        roles: [RolesApplicationEnum.EditConf],
      },
      edit: {
        path: "/transmissions/destinataires/editer/:id",
        root: "/transmissions/destinataires/editer",
        name: "transmissions/destinataires.edit",
        roles: [RolesApplicationEnum.EditConf],
      },
    },
    status: {
      view: {
        path: "/transmissions/statuts/consulter/:id",
        root: "/transmissions/statuts/consulter",
        name: "transmissions/statuts.view",
        roles: [RolesApplicationEnum.ReadConf],
      },
      table: {
        path: "/transmissions/statuts",
        name: "transmissions/statuts.table",
        roles: [RolesApplicationEnum.ReadConf],
      },
      add: {
        path: "/transmissions/statuts/ajouter",
        name: "transmissions/statuts.add",
        roles: [RolesApplicationEnum.EditConf],
      },
      edit: {
        path: "/transmissions/statuts/editer/:id",
        root: "/transmissions/statuts/editer",
        name: "transmissions/statuts.edit",
        roles: [RolesApplicationEnum.EditConf],
      },
    },
    transmissions: {
      view: {
        path: "/transmissions/transmissions/consulter/:id",
        root: "/transmissions/transmissions/consulter",
        name: "transmissions/transmissions.view",
        roles: [RolesApplicationEnum.ReadConf],
      },
      table: {
        path: "/transmissions/transmissions",
        name: "transmissions/transmissions.table",
        roles: [RolesApplicationEnum.ReadConf],
      },
      add: {
        path: "/transmissions/transmissions/ajouter",
        name: "transmissions/transmissions.add",
        roles: [RolesApplicationEnum.EditConf],
      },
      edit: {
        path: "/transmissions/transmissions/editer/:id",
        root: "/transmissions/transmissions/editer",
        name: "transmissions/transmissions.edit",
        roles: [RolesApplicationEnum.EditConf],
      },
    },

    /** Les périmètres */
    perimeters: {
      view: {
        path: "/transmissions/perimetres/consulter/:id",
        root: "/transmissions/perimetres/consulter",
        name: "transmissions/perimetres.view",
        roles: [RolesApplicationEnum.ReadConf],
      },
      table: {
        path: "/transmissions/perimetres",
        name: "transmissions/perimetres.table",
        roles: [RolesApplicationEnum.ReadConf],
      },
      add: {
        path: "/transmissions/perimetres/ajouter",
        name: "transmissions/perimetres.add",
        roles: [RolesApplicationEnum.EditConf],
      },
      edit: {
        path: "/transmissions/perimetres/editer/:id",
        root: "/transmissions/perimetres/editer",
        name: "transmissions/perimetres.edit",
        roles: [RolesApplicationEnum.EditConf],
      },
    },
    /** Les cellules client */
    customerCells: {
      view: {
        path: "/transmissions/cellulesclient/consulter/:id",
        root: "/transmissions/cellulesclient/consulter",
        name: "transmissions/cellulesclient.view",
        roles: [RolesApplicationEnum.ReadConf],
      },
      table: {
        path: "/transmissions/cellulesclient",
        name: "transmissions/cellulesclient.table",
        roles: [RolesApplicationEnum.ReadConf],
      },
      add: {
        path: "/transmissions/cellulesclient/ajouter",
        name: "transmissions/cellulesclient.add",
        roles: [RolesApplicationEnum.EditConf],
      },
      edit: {
        path: "/transmissions/cellulesclient/editer/:id",
        root: "/transmissions/cellulesclient/editer",
        name: "transmissions/cellulesclient.edit",
        roles: [RolesApplicationEnum.EditConf],
      },
    },
  },
  /** Les produits du site Avec */
  avecProducts: {
    view: {
      path: "/avecproduits/consulter/:id",
      root: "/avecproduits/consulter",
      name: "avecproduits.view",
      roles: [RolesApplicationEnum.ReadAvecProduct],
    },
    table: {
      path: "/avecproduits",
      name: "/avecproduits.table",
      roles: [RolesApplicationEnum.ReadAvecProduct],
    },
    add: {
      path: "/avecproduits/ajouter",
      name: "/avecproduits.add",
      roles: [RolesApplicationEnum.EditAvecProduct],
    },
    edit: {
      path: "/avecproduits/editer/:id",
      root: "/avecproduits/editer",
      name: "/avecproduits.edit",
      roles: [RolesApplicationEnum.EditAvecProduct],
    },
  },
  /** les zones de mobilité */
  mobilityzones: {
    view: {
      path: "/zonesdemobilite/consulter/:id",
      root: "/zonesdemobilite/consulter",
      name: "zonesdemobilite.view",
      roles: [RolesApplicationEnum.ReadUser],
    },
    table: {
      path: "/zonesdemobilite",
      name: "zonesdemobilite.table",
      roles: [RolesApplicationEnum.ReadUser],
    },
    add: {
      path: "/zonesdemobilite/ajouter",
      name: "zonesdemobilite.add",
      roles: [RolesApplicationEnum.EditUser],
    },
    edit: {
      path: "/zonesdemobilite/editer/:id",
      root: "/zonesdemobilite/editer",
      name: "zonesdemobilite.edit",
      roles: [RolesApplicationEnum.EditUser],
    },
  },
  /** Les avantages du groupe */
  advantages: {
    view: {
      path: "/avantagesgroupe/consulter/:id",
      root: "/avantagesgroupe/consulter",
      name: "avantagesgroupe.view",
      roles: [RolesApplicationEnum.ReadUser],
    },
    table: {
      path: "/avantagesgroupe",
      name: "avantagesgroupe.table",
      roles: [RolesApplicationEnum.ReadUser],
    },
    add: {
      path: "/avantagesgroupe/ajouter",
      name: "avantagesgroupe.add",
      roles: [RolesApplicationEnum.EditUser],
    },
    edit: {
      path: "/avantagesgroupe/editer/:id",
      root: "/avantagesgroupe/editer",
      name: "avantagesgroupe.edit",
      roles: [RolesApplicationEnum.EditUser],
    },
  },
  /** Les primes au collaborateur */
  primes: {
    view: {
      path: "/primes/consulter/:id",
      root: "/primes/consulter",
      name: "primes.view",
      roles: [RolesApplicationEnum.ReadDictionary],
    },
    table: {
      path: "/primes",
      name: "primes.table",
      roles: [RolesApplicationEnum.ReadDictionary],
    },
    add: {
      path: "/primes/ajouter",
      name: "primes.add",
      roles: [RolesApplicationEnum.EditDictionary],
    },
    edit: {
      path: "/primes/editer/:id",
      root: "/primes/editer",
      name: "primes.edit",
      roles: [RolesApplicationEnum.EditDictionary],
    },
  },
  /** Les contextes des rubriques */
  rubricsContext: {
    view: {
      path: "/contexterubriques/consulter/:id",
      root: "/contexterubriques/consulter",
      name: "contexterubriques.view",
      roles: [RolesApplicationEnum.ReadServiceSFR],
    },
    table: {
      path: "/contexterubriques",
      name: "contexterubriques.table",
      roles: [RolesApplicationEnum.ReadServiceSFR],
    },
    add: {
      path: "/contexterubriques/ajouter",
      name: "contexterubriques.add",
      roles: [RolesApplicationEnum.EditServiceSFR],
    },
    edit: {
      path: "/contexterubriques/editer/:id",
      root: "/contexterubriques/editer",
      name: "contexterubriques.edit",
      roles: [RolesApplicationEnum.EditServiceSFR],
    },
  },
  /** Les status 'disponible pour le travail */
  fitForWorkStatus: {
    view: {
      path: "/statusdisponibletravail/consulter/:id",
      root: "/statusdisponibletravail/consulter",
      name: "statusdisponibletravail.view",
      roles: [RolesApplicationEnum.ReadUser],
    },
    table: {
      path: "/statusdisponibletravail",
      name: "statusdisponibletravail.table",
      roles: [RolesApplicationEnum.ReadUser],
    },
    add: {
      path: "/statusdisponibletravail/ajouter",
      name: "statusdisponibletravail.add",
      roles: [RolesApplicationEnum.EditUser],
    },
    edit: {
      path: "/statusdisponibletravail/editer/:id",
      root: "/statusdisponibletravail/editer",
      name: "statusdisponibletravail.edit",
      roles: [RolesApplicationEnum.EditUser],
    },
  },
  /** Les consentements */
  consents: {
    view: {
      path: "/consentements/consulter/:id",
      root: "/consentements/consulter",
      name: "consentements.view",
      roles: [RolesApplicationEnum.ReadDictionary],
    },
    table: {
      path: "/consentements",
      name: "consentements.table",
      roles: [RolesApplicationEnum.ReadDictionary],
    },
    add: {
      path: "/consentements/ajouter",
      name: "consentements.add",
      roles: [RolesApplicationEnum.EditDictionary],
    },
    edit: {
      path: "/consentements/editer/:id",
      root: "/consentements/editer",
      name: "consentements.edit",
      roles: [RolesApplicationEnum.EditDictionary],
    },
  },
  /** Les types de tarifs pour les établissements */
  pricesTypes: {
    view: {
      path: "/typetarifs/consulter/:id",
      root: "/typetarifs/consulter",
      name: "typetarifs.view",
      roles: [RolesApplicationEnum.ReadProduct],
    },
    table: {
      path: "/typetarifs",
      name: "typetarifs.table",
      roles: [RolesApplicationEnum.ReadProduct],
    },
    add: {
      path: "/typetarifs/ajouter",
      name: "typetarifs.add",
      roles: [RolesApplicationEnum.AdminProduct],
    },
    edit: {
      path: "/typetarifs/editer/:id",
      root: "/typetarifs/editer",
      name: "typetarifs.edit",
      roles: [RolesApplicationEnum.AdminProduct],
    },
  },
  /** Les unité de tarifs pour les établissements */
  pricesUnits: {
    view: {
      path: "/unitetarifs/consulter/:id",
      root: "/unitetarifs/consulter",
      name: "unitetarifs.view",
      roles: [RolesApplicationEnum.ReadProduct],
    },
    table: {
      path: "/unitetarifs",
      name: "unitetarifs.table",
      roles: [RolesApplicationEnum.ReadProduct],
    },
    add: {
      path: "/unitetarifs/ajouter",
      name: "unitetarifs.add",
      roles: [RolesApplicationEnum.AdminProduct],
    },
    edit: {
      path: "/unitetarifs/editer/:id",
      root: "/unitetarifs/editer",
      name: "unitetarifs.edit",
      roles: [RolesApplicationEnum.AdminProduct],
    },
  },
  /** Les tarifs par établissements */
  priceEstablishment: {
    view: {
      path: "/tarifsetablissement/consulter/:id",
      root: "/tarifsetablissement/consulter",
      name: "tarifsetablissement.view",
      roles: [RolesApplicationEnum.ReadPriceEstablishment],
    },
    table: {
      path: "/tarifsetablissement",
      name: "tarifsetablissement.table",
      roles: [RolesApplicationEnum.ReadPriceEstablishment],
    },
    add: {
      path: "/tarifsetablissement/ajouter",
      name: "tarifsetablissement.add",
      roles: [RolesApplicationEnum.EditPriceEstablishment],
    },
    edit: {
      path: "/tarifsetablissement/editer/:id",
      root: "/tarifsetablissement/editer",
      name: "tarifsetablissement.edit",
      roles: [RolesApplicationEnum.EditPriceEstablishment],
    },
  },
  /** Les tarifs groupe */
  priceGroup: {
    view: {
      path: "/tarifsgroupe/consulter/:id",
      root: "/tarifsgroupe/consulter",
      name: "tarifsgroupe.view",
      roles: [RolesApplicationEnum.ReadProduct],
    },
    table: {
      path: "/tarifsgroupe",
      name: "tarifsgroupe.table",
      roles: [RolesApplicationEnum.ReadProduct],
    },
    add: {
      path: "/tarifsgroupe/ajouter",
      name: "tarifsgroupe.add",
      roles: [RolesApplicationEnum.AdminProduct],
    },
    edit: {
      path: "/tarifsgroupe/editer/:id",
      root: "/tarifsgroupe/editer",
      name: "tarifsgroupe.edit",
      roles: [RolesApplicationEnum.AdminProduct],
    },
  },
  /** Les categories de produits du site Avec */
  avecProductsCategory: {
    view: {
      path: "/avecproduitscategorie/consulter/:id",
      root: "/avecproduitscategorie/consulter",
      name: "avecproduitscategorie.view",
      roles: [RolesApplicationEnum.ReadProduct],
    },
    table: {
      path: "/avecproduitscategorie",
      name: "avecproduitscategorie.table",
      roles: [RolesApplicationEnum.ReadProduct],
    },
    add: {
      path: "/avecproduitscategorie/ajouter",
      name: "avecproduitscategorie.add",
      roles: [RolesApplicationEnum.AdminProduct],
    },
    edit: {
      path: "/avecproduitscategorie/editer/:id",
      root: "/avecproduitscategorie/editer",
      name: "avecproduitscategorie.edit",
      roles: [RolesApplicationEnum.AdminProduct],
    },
  },
  /** Les categories de produits du site Avec */
  faqs: {
    view: {
      path: "/faqs/consulter/:id",
      root: "/faqs/consulter",
      name: "faqs.view",
      roles: [RolesApplicationEnum.ReadFaqs],
    },
    table: {
      path: "/faqs",
      name: "faqs.table",
      roles: [RolesApplicationEnum.ReadFaqs],
    },
    add: {
      path: "/faqs/ajouter",
      name: "faqs.add",
      roles: [RolesApplicationEnum.EditFaqs],
    },
    edit: {
      path: "/faqs/editer/:id",
      root: "/faqs/editer",
      name: "faqs.edit",
      roles: [RolesApplicationEnum.EditFaqs],
    },
  },
  /** La gestion des template de capacitaire */
  capacities: {
    view: {
      path: "/capacitaire/consulter",
      root: "/capacitaire/consulter",
      name: "capacitaire.view",
      roles: [RolesApplicationEnum.ReadTemplateCapacities],
    },
    table: {
      path: "/capacitaire",
      name: "capacitaire.table",
      roles: [RolesApplicationEnum.ReadTemplateCapacities],
    },
    edit: {
      path: "/capacitaire/editer",
      root: "/capacitaire/editer",
      name: "capacitaire.edit",
      roles: [RolesApplicationEnum.EditTemplateCapacities],
    },
  },
  establishmentCapacities: {
    view: {
      path: "/capacite/etablissement",
      name: "etablissementcapacite",
      roles: [RolesApplicationEnum.ReadCapacitiesEstablishment],
    },
  },
  dgauth: {
    view: {
      path: "/dgauth/view",
      root: "/dgauth/view",
      name: "/dgauth/view",
      roles: [RolesApplicationEnum.ReadDgauth],
    },
  },
  dgauthservices: {
    view: {
      path: "/dgauth/viewservices",
      root: "/dgauth/viewservices",
      name: "/dgauth/viewservices",
      roles: [RolesApplicationEnum.ReadDgauth],
    },
  },
  dgauthfunctions: {
    view: {
      path: "/dgauth/viewfonction",
      root: "/dgauth/viewfonction",
      name: "/dgauth/viewfonction",
      roles: [RolesApplicationEnum.ReadDgauth],
    },
  },
  dgauthusers: {
    view: {
      path: "/dgauth/viewusers",
      root: "/dgauth/viewusers",
      name: "/dgauth/viewusers",
      roles: [RolesApplicationEnum.ReadUserDgauth],
    },
  },
  dgauthusersroles: {
    view: {
      path: "/dgauth/viewusersroles",
      root: "/dgauth/viewusersroles",
      name: "/dgauth/viewusersroles",
      roles: [RolesApplicationEnum.ReadUserDgauth],
    },
  },
  dgauthcustomerroles: {
    view: {
      path: "/dgauth/viewcustomerroles",
      root: "/dgauth/viewcustomerroles",
      name: "/dgauth/viewcustomerroles",
      roles: [RolesApplicationEnum.ReadDgauth],
    },
  },

  practitionerfile: {
    view: {
      path: "/practicien/fichepraticien",
      root: "/practicien/fichepraticien",
      name: "/practicien/fichepraticien",
      roles: [RolesApplicationEnum.ReadPracticioner],
    },
  },
  createpractitioner: {
    view: {
      path: "/practicien/creerpraticien",
      root: "/practicien/creerpraticien",
      name: "/practicien/creerpraticien",
      roles: [RolesApplicationEnum.EditPracticioner],
    },
  },
  medicalexpertises: {
    view: {
      path: "/expertisemedicale/consulter/:id",
      root: "/expertisemedicale/consulter",
      name: "expertisemedicale.view",
      roles: [RolesApplicationEnum.ReadPracticioner],
    },
    table: {
      path: "/expertisemedicale",
      name: "expertisemedicale.table",
      roles: [RolesApplicationEnum.ReadPracticioner],
    },
    add: {
      path: "/expertisemedicale/ajouter",
      name: "expertisemedicale.add",
      roles: [RolesApplicationEnum.EditPracticioner],
    },
    edit: {
      path: "/expertisemedicale/editer/:id",
      root: "/expertisemedicale/editer",
      name: "expertisemedicale.edit",
      roles: [RolesApplicationEnum.EditPracticioner],
    },
  },
};
