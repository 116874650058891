import * as ConfApi from "@/api/conf/index.js";
import * as CustomersApi from "@/api/customers/index.js";
import * as DictionaryApi from "@/api/dictionaries/index.js";
import * as EstablishmentApi from "@/api/establishments/index.js";
import * as PractitionersApi from "@/api/practitioners/index.js";
import * as SfrApi from "@/api/sfr/index.js";
import * as UsersApi from "@/api/users/index.js";

import * as BackendApi from "@/api/backend/index.js";
import * as DevApi from "@/api/dev/index.js";
import * as DgAuthApi from "@/api/dgauth/index.js";

import * as loginService from "@/service/login_service.js";
import * as loginStoreService from "@/service/login_store_service.js";

import * as logger from "@/tools/logger.js";

const _CLASS_NAME = "Api";
const _METHOD_CONSTRUCTOR = "constructor";

/**
 * Classe d'accès aux appels api de chaque famille d'objets
 */
export default class Api {
  constructor() {
    logger.debugFull(
      _CLASS_NAME,
      _METHOD_CONSTRUCTOR,
      "Instanciation des objet api."
    );

    /** Récupération de la clé d'api obligatoire pour TOUS les get */
    let apiKey = process.env.VUE_APP_API_KEY;

    /** Création d'une fonction de callback de récupération du token */
    let getTokenFunction = () => {
      try {
        loginService.getExpirationAndReniewTokenIp();
        return loginStoreService.getToken();
        // return loginStoreService.getToken();
      } catch (error) {
        console.error(error);
      }
    };

    /**Hook de récupération du token APM. */
    let getApmTokenFunction = () => {
      try {
        return loginStoreService.getTokenDgauth();
      } catch (error) {
        console.error(error);
      }
    };

    /** Création du client http Conf et récupération des apis */
    let confHttpClient = ConfApi.initHttpClient(
      apiKey,
      getTokenFunction,
      getApmTokenFunction
    );
    this.confApi = ConfApi.initApi(confHttpClient);

    /** Création du client http Users et récupération des apis */
    let usersHttpClient = UsersApi.initHttpClient(
      apiKey,
      getTokenFunction,
      getApmTokenFunction
    );
    this.usersApi = UsersApi.initApi(usersHttpClient);

    /** Création du client http SFR et récupération des apis */
    let sfrHttpClient = SfrApi.initHttpClient(
      apiKey,
      getTokenFunction,
      getApmTokenFunction
    );
    this.sfrApi = SfrApi.initApi(sfrHttpClient);

    /** Création du client http ESTABLISHMENT et récupération des apis */
    let establishmentHttpClient = EstablishmentApi.initHttpClient(
      apiKey,
      getTokenFunction,
      getApmTokenFunction
    );
    this.establishmentApi = EstablishmentApi.initApi(establishmentHttpClient);

    /** Création du client http DICTIONARIES et récupération des apis */
    let dictionaryHttpClient = DictionaryApi.initHttpClient(
      apiKey,
      getTokenFunction,
      getApmTokenFunction
    );
    this.dictionaryApi = DictionaryApi.initApi(dictionaryHttpClient);

    /** Création du client http CUSTOMERS et récupération des apis */
    let customerHttpClient = CustomersApi.initHttpClient(
      apiKey,
      getTokenFunction,
      getApmTokenFunction
    );
    this.customerApi = CustomersApi.initApi(customerHttpClient);

    /** Création du client http PRACTITIONERS et récupération des apis */
    let practitionerHttpClient = PractitionersApi.initHttpClient(
      apiKey,
      getTokenFunction,
      getApmTokenFunction
    );
    this.practitionerApi = PractitionersApi.initApi(practitionerHttpClient);

    /** Création du client http backend  et récupération des apis */
    let backendHttpClient = BackendApi.initHttpClient(
      apiKey,
      getTokenFunction,
      getApmTokenFunction
    );
    this.backendApi = BackendApi.initApi(backendHttpClient);

    /** Création du client http pour de dev et récupération des apis. */
    let devHttpClient = DevApi.initHttpClient(apiKey, getTokenFunction);
    this.devApi = DevApi.initApi(devHttpClient);
    this.devHttpClient = devHttpClient;

    /** Création du client http DGAUTH et récupération des apis */
    let dgAuthHttpClient = DgAuthApi.initHttpClient(
      apiKey,
      getTokenFunction,
      getApmTokenFunction
    );
    this.dgAuthApi = DgAuthApi.initApi(dgAuthHttpClient);
  }

  /////////////////////// CONF /////////////////////////
  /** La conf de l'ihm de consultation modification */
  getAppConsulterModifierApi() {
    return this.confApi.appConsulterModifier;
  }
  /** La conf pour l'app "rédiger un contrat de travail" */
  getAppWorksContractApi() {
    return this.confApi.appConfWorksContract;
  }
  /** La conf pour l'app "Avec" spécialisée établissements */
  getAppAvecEstablishmentApi() {
    return this.confApi.appConfAvecEstablishment;
  }
  /** La conf pour l'app "Avec" spécialisée super-établissements */
  getAppAvecSuperEstablishmentApi() {
    return this.confApi.appConfAvecSuperEstablishment;
  }
  /** La conf pour l'app "Avec" spécialisée sous-activités */
  getAppAvecSubactivityApi() {
    return this.confApi.appConfAvecSubactivity;
  }
  /** Les niveaux d'urgence ticketing */
  getEmergencyLevelTicketing() {
    return this.confApi.emergencyLevelTicketing;
  }
  /** Les niveaux d'urgence transmission */
  getEmergencyLevelTransmission() {
    return this.confApi.emergencyLevelTransmission;
  }
  /** Les périmètres du ticketing */
  getTicketingPerimeterApi() {
    return this.confApi.ticketingPerimeter;
  }
  /** Les types de destinataires (recipient_type) */
  getTicketingRecipientType() {
    return this.confApi.ticketingRecipientType;
  }
  /** Les status des ticket */
  getTicketingStatus() {
    return this.confApi.ticketingStatus;
  }
  /** Les objects / sous-object des ticket */
  getTicketingObjectSubobject() {
    return this.confApi.ticketingObjectSubobject;
  }

  getTransmissionEcoSystemsApi() {
    return this.confApi.transmission.ecosystems;
  }

  getTransmissionMediumsApi() {
    return this.confApi.transmission.mediums;
  }

  getTransmissionOtherRecipientTypespi() {
    return this.confApi.transmission.otherRecipientTypes;
  }

  getTransmissionStatusApi() {
    return this.confApi.transmission.status;
  }

  getTransmissionObjectsApi() {
    return this.confApi.transmission.objects;
  }
  /** Les produits du site avec */
  getTransmissionsAvecProductsApi() {
    return this.confApi.transmission.avecProducts;
  }
  /** Les périmètres des transmissions */
  getPerimetersApi() {
    return this.confApi.transmission.perimeters;
  }
  /** Les cellules client */
  getCustomerCellsApi() {
    return this.confApi.transmission.customerCells;
  }
  /** les spécialisations de label */
  getAppAvecIndicatorsApi() {
    return this.confApi.appConfAvecIndicators;
  }
  /** Les applications métier pour les établissement */
  getConfEstablishmentBusinessApp() {
    return this.confApi.confEstablishmentBusinessApp;
  }
  /** Les types de tarifs établissement */
  getPriceTypesApi() {
    return this.confApi.priceTypes;
  }
  /** Les unités de tarifs établissement */
  getPriceUnitsApi() {
    return this.confApi.priceUnits;
  }
  /** Les tarifs de produits pour les établissements */
  getProductPricesEstablishment() {
    return this.confApi.productPricesEstablishment;
  }
  /** Les tarifs de prduits pour le groupe */
  getProductPricesGroupe() {
    return this.confApi.productPriceGroupe;
  }
  /** Les category de produit */
  getProductCategory() {
    return this.confApi.avecProductCategory;
  }
  /** Les category de produit */
  getFaqActivity() {
    return this.confApi.faqActivity;
  }
  /** Les category de produit */
  getFaqSubActivity() {
    return this.confApi.faqSubActivity;
  }

  /////////////////////// ESTABLISHMENT /////////////////////////
  /** Les établissements */
  getEtablishmentApi() {
    return this.establishmentApi.establishments;
  }
  /** Les status d'un établisssemnt */
  getEstablishmentStatusApi() {
    return this.establishmentApi.establishmentsStatus;
  }
  /** La partie JURIS d'un établisssemnt */
  getEstablishmentJurisApi() {
    return this.establishmentApi.establishmentsJuris;
  }
  /** Les structures juridiques */
  getLegalStructuresApi() {
    return this.establishmentApi.legalStructures;
  }
  /** Les natures de structures juridique  */
  getLegalStructureTypeApi() {
    return this.establishmentApi.legalStructureType;
  }
  /**  Les types de contrat externe (mutuelle ...) */
  getExternalContractTypesApi() {
    return this.establishmentApi.externalContractTypes;
  }
  /**  Les contacts de contrat externe */
  getExternalContractContactApi() {
    return this.establishmentApi.externalContractContact;
  }
  /** Les actionnaires */
  getStockholdersApi() {
    return this.establishmentApi.stockholder;
  }
  /** Les évènements d'une structure juridique */
  getLegalStructureEventTypeApi() {
    return this.establishmentApi.legalStructureEventType;
  }
  /** Les status 'une structure juridique */
  getLegalStructuresStatusApi() {
    return this.establishmentApi.legalStructureStatus;
  }
  getLegalRepresentativeStatusApi() {
    return this.establishmentApi.legalRepresentativeStatus;
  }
  /** Les actionnaires d'une structure juridique */
  getLegalStructureStockholderApi() {
    return this.establishmentApi.legalStructureStockholder;
  }
  /** Les type de contrat externe à une structure juridique */
  getLegalStructureExternalContractApi() {
    return this.establishmentApi.legalStructureExternalContracts;
  }
  // Les types dévènements d'une structure juridique
  getLegalStructureEventApi() {
    return this.establishmentApi.legalStructureEvents;
  }
  /** Les super établissements */
  getSuperEstablishmentApi() {
    return this.establishmentApi.superEstablishment;
  }
  /** Les référents de fonction par établissement*/
  getReferentFunctionApi() {
    return this.establishmentApi.referentFunction;
  }
  /** Les super structure juridique */
  getSuperLegalStructureApi() {
    return this.establishmentApi.superLegalStructure;
  }
  /** Les capacitaires */
  getCapacitiesApi() {
    return this.establishmentApi.capacities;
  }
  /** Les destinataires pour les offres d'emploi */
  getJobOfferRecipientApi() {
    return this.establishmentApi.jobOfferRecipientApi;
  }

  /////////////////////// SFR /////////////////////////
  /** Les activités */
  getActivitiesApi() {
    return this.sfrApi.activities;
  }
  /** Les fonctions */
  getFunctionApi() {
    return this.sfrApi.functions;
  }
  /** Les métiers */
  getMetierApi() {
    return this.sfrApi.metiers;
  }
  /** Les rubriques */
  getRubricsApi() {
    return this.sfrApi.rubrics;
  }
  /** Les rubriques globales */
  getGlobalRubricsApi() {
    return this.sfrApi.globalRubrics;
  }
  /** Les services */
  getServicesApi() {
    return this.sfrApi.services;
  }
  /** Les sous-activités */
  getSubActivitiesApi() {
    return this.sfrApi.subActivities;
  }
  /** Les marques métier */
  getDomainBrandsApi() {
    return this.sfrApi.domainBrands;
  }
  /** Les super activités */
  getSuperActivitiesApi() {
    return this.sfrApi.superActivities;
  }
  /** Les contextes des rubriques */
  getRubricsContextApi() {
    return this.sfrApi.rubricsContextApi;
  }

  /////////////////////// DICTIONARY /////////////////////////
  /** Les motifs maladie */
  getDiseaseReasonApi() {
    return this.dictionaryApi.diseaseReasons;
  }
  /** Les temps de travail < 24h */
  getWorkDurationReasonApi() {
    return this.dictionaryApi.workDurationReason;
  }
  /** Les natures de remboursement */
  getRefundTypesApi() {
    return this.dictionaryApi.refundTypes;
  }
  /** Les organisations de temps de travail */
  getWorkingTimeOrganisationApi() {
    return this.dictionaryApi.workingTimeOrganisation;
  }
  /** Les origines de prospection */
  getOriginProspectApi() {
    return this.dictionaryApi.prospectOrigin;
  }
  /** Les contextes notes de frais */
  getExpenseReportContextsApi() {
    return this.dictionaryApi.expenseReportContext;
  }
  /** Les motifs cdd */
  getCddReasonsApi() {
    return this.dictionaryApi.cddReasons;
  }
  /** Les motifs congés */
  getVacationReasonsApi() {
    return this.dictionaryApi.vacationReasons;
  }
  /** Les acronymes */
  getAcronymsApi() {
    return this.dictionaryApi.acronyms;
  }
  /** les applications */
  getApplicationApi() {
    return this.dictionaryApi.applications;
  }
  /** les métiers rome issus de pole emploi */
  getRomeJobsApi() {
    return this.dictionaryApi.romeJobs;
  }
  /** les métiers créés par Doctegestio */
  getInternalJobsApi() {
    return this.dictionaryApi.internalJobs;
  }
  /** Les grands domaines ROME */
  getRomeHightDomainsApi() {
    return this.dictionaryApi.romeHightDomains;
  }
  /** Les primes */
  getPrimesApi() {
    return this.dictionaryApi.primes;
  }

  /////////////////////// USERS /////////////////////////

  getUsersApi() {
    return this.usersApi.users;
  }
  getCollaboratorsApi() {
    return this.usersApi.collaborators;
  }
  /** Les genres (sexes) */
  getGenderApi() {
    return this.usersApi.gender;
  }
  /** Les civilités (monsieur, madame, ...) */
  getCivilitiesApi() {
    return this.usersApi.civilities;
  }
  /** Les temps de travail */
  getWorksDurationApi() {
    return this.usersApi.worksDuration;
  }
  /** Les type utilisateurs */
  getUsersTypeApi() {
    return this.usersApi.userType;
  }
  /** Les conventions collectives */
  getCollectiveAgreementApi() {
    return this.usersApi.collectiveAgreement;
  }
  /** Les natures de relation de travail */
  getWorkRelationshipTypeApi() {
    return this.usersApi.workRelationshipType;
  }
  /** les types de rémunération */
  getRemunerationTypeApi() {
    return this.usersApi.remunerationType;
  }
  /** les motifs de recrutement */
  getRecruitingReasonApi() {
    return this.usersApi.recruitingReason;
  }
  /** les points requis pour une annonce */
  getJobAdRequiredPointApi() {
    return this.usersApi.jobAdRequiredPoint;
  }
  /** Les zones de mobilité */
  getMobilityZonesApi() {
    return this.usersApi.mobilityZone;
  }
  /** Les avantages groupes */
  getAdvantagesApi() {
    return this.usersApi.advantages;
  }
  /** Les titres employées */
  getEmployeesTitleApi() {
    return this.usersApi.employeesTitle;
  }
  /** les statut disponible pour le travail */
  getFitForWorkStatusApi() {
    return this.usersApi.fitForWorkStatus;
  }

  /////////////////////// CUSTOMERS /////////////////////////

  /** les types de consentements */
  getConsentApi() {
    return this.customerApi.consents;
  }

  /////////////////////// PRACTITIONERS /////////////////////////
  /** les praticiens */
  getPractitionerApi() {
    return this.practitionerApi.practitioner;
  }  
  getCollaboratorPractitionerApi() {
    return this.practitionerApi.collaboratorPratitioners;
  }
  /** les spécialités médicales */
  getMedicalSpecialitiesApi() {
    return this.practitionerApi.medicalSpecialities;
  }
  /** Les expertises médicales */
  getMedicalExpertisesApi() {
    return this.practitionerApi.medicalExpertises;
  }
  /** Les profession médicales */
  getMedicalProfessionApi() {
    return this.practitionerApi.medicalProfession;
  }

  /////////////////////// BACKEND /////////////////////////
  getTicketingApi() {
    return this.backendApi.ticketing;
  }
  /** La gestion des droits dgauth */
  getAuthAdminApi() {
    return this.backendApi.authAdmin;
  }

  /////////////////////// DEV /////////////////////////
  getDevHttpClient() {
    return this.devHttpClient;
  }

  /////////////////////// DGAUTH /////////////////////////
  getDgAuthApi() {
    return this.dgAuthApi.dgauth;
  }
}
