import jwt from "jsonwebtoken";
//import * as loginService from "@/service/login_service.js";
import * as loginStoreService from "@/service/login_store_service.js";
import DgAuthHttpClient from "@/api/dgauth/dgauth_http_client.js";
import DgAuthApi from "@/api/dgauth/dgauth_api.js";

import * as logger from "../tools/logger"

import {  allRoles } from "./roles/roles_apm";
import { build, RoleTypes } from "./roles/roles_application"; 
//RolesApplicationEnum
const _CAN_EDIT_DATA = "caneditdata";
const _APM_TOKEN = "apm-token";
const _APM_ROLES = "apm-roles";
const _ROLES = "roles";

/**
 * As pour rôle :
 *  - extraire les rôles dgauth du token
 *  - construire les rôles applicatifs depuis les rôles extraits
 *  - définir si l'utilisateur as le mode édition 
 */
export function setRole() {
  let canEdit = false;
  // Les rôles dgauth
  let roles = []; 

  // Récupération de l'email et du token dgauth dans le session storage
  //let email = loginStoreService.getEmail();
  let tokenDgauth = loginStoreService.getTokenDgauth();

  // Décodage du token et extraction des rôles du l'utilisateur
  if (tokenDgauth) {
    let decode = jwt.decode(tokenDgauth);
    roles = decode.authorized;
  }

  // Enregistrement des rôles dgauth de l'utilisateur
  loginStoreService.setDgauthRoles(roles);
  
  // Construction des rôles applicatifs du user
  let applicationRoles = buildApplicationRoles(build(), roles);

  // Demande si l'utilisateur possède le droit de modification sur l'ihm
  canEdit = canEditIhm(build(), applicationRoles);

  logger.error("Liste des rôles applicatifs de l'utilisateur : ", applicationRoles);

  // Enregistrement des rôles applicatifs qui matchent avec ceux du token
  loginStoreService.setAppRoles(applicationRoles);
  // Enregistre le droit d'édition de l'utilisateur
  loginStoreService.setCanEditData(canEdit);

}



/** Supression des rôles setté pour l'utilisateur */
export function removeRole() {
  sessionStorage.removeItem(_APM_TOKEN);
  sessionStorage.removeItem(_CAN_EDIT_DATA);
  sessionStorage.removeItem(_APM_ROLES);
  sessionStorage.removeItem(_ROLES);
}



/** Détermine en fonction des rôles applicatifs de l'utilisateur s'il a au moins un droit de modification */
export function canEditIhm(rolesReference, applicationRoles) {
  let canEdit = false;

  // Parcours la liste des role applicatif de l'utilisateur
  for(let role of applicationRoles) {
    // Récupération du role en cours dans les r^les de référence
    let roleRef = rolesReference.find((r) => r.name == role);

    // Matching des types de rôles en édition
    if (roleRef.type == RoleTypes.Writter
      || roleRef.type == RoleTypes.Admin) {
        canEdit = true;
        break;
    }
  }

  return canEdit;
}


/** Retourne les rôles Dgauth du collaborateur. */
export function getUserRoles() {
  return loginStoreService.getDgauthRoles();
}

/** Retourne la définition des rôles applicatifs. */
export function getRoles() {
  return loginStoreService.getAppRoles();
}

/**
 * retourne true si l'utilisateur possède au moins un rôle d'édition
 */
export function roleCanEdit() {
  let can = loginStoreService.getCanEditData();

  //on utilise JSON.parse car la variable can contient une string ('true'/'false').
  return JSON.parse(can) || false;
}

/**
 * retourne true si l'utilisateur peut modifier les données (create/update/delete)
 */
export function hasRoles(applicationRoles = null) {
  if (!applicationRoles) {
    let can = sessionStorage.getItem(_CAN_EDIT_DATA);
    //on utilise JSON.parse car la variable can contient une string ('true'/'false').
    return JSON.parse(can) || false;
  } else {

    let roles = getRoles(); //les rôles du collaborateur

    let all = true;

    // Si le tableau de rôle est vide on as pas de droit
    if (applicationRoles.length == 0) {
      all = false;
    } else {
      for (let r of applicationRoles) {
        if (!roles.includes(r)) {
          all = false;
        }
      }
    }

    return all;
  }
}

/** 
 * Retourne true si l'utilisateur à un des rôles passé en param 
*/
export function hasOneOfRole(applicationRole = null) {
  if (applicationRole) {
    // Récupération des rôles stockés de l'utilisateur .
    let roles = getRoles();

    // Parcours la liste des rôles demandés
    // Si un des rôles est présent retourne true.    
    if(roles) {
      for (let r of applicationRole) {
        if (roles.includes(r)) {
          return true;
        }
      }
    }
  }
  
  return false;
}

/** 
 * Enregistrer le token Dgauth (gestion de droits) dans la session. 
*/
export function saveDgauth(token) {
  // Sauvegarde du token dgauth
  loginStoreService.setTokenDgauth(token);

  // Décodage du token
  let tokendecoded = jwt.decode(token);

  // On stocke l'expiration du token
  loginStoreService.setExpiresTokenDgauth(Number(tokendecoded.exp)*1000); 
}

/** Renouvelle le token dgauth */
export async function apmTokenReniew() {

  // Crée un client http pour la récupération du token DGAuth
  let httpClient = new DgAuthHttpClient();
  httpClient.getToken = () => loginStoreService.getToken();
  httpClient.api_key = process.env.VUE_APP_API_KEY;
  let api = new DgAuthApi(httpClient);

  // Appel à l'api de récupération du token dgauth puis enregistrement dans le sessionstorage
  let token = await api.getRolesForUser();
  saveDgauth(token);

  // // Récupération de l'email du user
  // let email = loginStoreService.getEmail();

  // // Mapping des rôles apm et application
  // setRole(email, token);
  setRole();
}

/** Construction un tableau des rôles hérités en fonction des rôles passés en paramètre */
export function getApmHeritageRoles(apmRoles) {
  let heritedRoleUser = [];

  // Liste des rôles apm avec héritage
  let apmRefRoles = allRoles();
  
  // Parcours de tous les rôles apm utilisateur 
  // pour récupération de tous les rôles hérités.
  for (let apm of apmRoles) {
    // Récupération du rôle dans l'héritage
    let role = apmRefRoles.find((r) => r.name == apm);
    
    if (role) {
      // Récupération des rôles qui héritent du rôle hérité
      let herited = [...role.roles];
      
      // Récupération de tous les rôles hérité qui ne sont pas déjà dans la liste des rôles du user
      let rolesapm = herited.filter((r) => !heritedRoleUser.includes(r));
      
      // Ajout des rôles au tableau
      heritedRoleUser.push(...rolesapm);
    }    
  }
  return heritedRoleUser;
}


/** 
 * Construire la liste des rôles applicatifs du collaborateur
 * en fonction des rôles dgauth de ce dernier.
*/
function buildApplicationRoles(applicationRoles, dgauthRolesUser) {
  let roles = [];

  // Tableau pour les rôles apm avec héritage
  let heritedRoleUser = getApmHeritageRoles(dgauthRolesUser);

  // Parcours de tous les rôles applicatifs
  for (let roleApp of applicationRoles) {
    let check = true;

    // Chaque rôle applicatif contient une liste de rôles apm nécessaires à l'obtention du rôle applicatif
    // donc on parcours la liste de ces rôles pour vérifier si l'utilisateur possède ceux nécessaires
    if (roleApp.expected.length == 0) { check = false; }
    
    // Parcours des rôles nécessaires à l'attribution du rôle
    for (let expected of roleApp.expected) {
      if (!heritedRoleUser.includes(expected)) {
        check = false;
      }
    }

    //ON vérife les rôles contextuels (avec des ||)
    if (roleApp.enabled) {
      check = roleApp.enabled(heritedRoleUser);        
    }

    // Si l'utilisateur as tous les rôles on ajoute le rôle en cours
    if (check) {
      roles.push(roleApp.name);
    }
  }

  return roles;
}


