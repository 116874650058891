import DgAuthHttpClient from "@/api/dgauth/dgauth_http_client.js";
import DgAuthApi from "@/api/dgauth/dgauth_api.js";

/** Construction du client Http de l'api du backend */
export function initHttpClient(apiKey, tokenFunction, apmTokenFunction) {
  //on instancie les clients http
  let httpclient = new DgAuthHttpClient();
  httpclient.getToken = tokenFunction;
  httpclient.getApmToken = apmTokenFunction;
  httpclient.api_key = apiKey;

  return httpclient;
}

/** Instanciation des apis */
export function initApi(httpclient) {
  return {
    dgauth: new DgAuthApi(httpclient),
  };
}
