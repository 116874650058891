import * as logger from "@/tools/logger.js";

const _CLASS_NAME = "EtablishmentApi";

const _M_GET_DIGITAL_NAMES = "getDigitalNames";
const _M_GET_ETABLIS_BY_DIGI_NAME = "getEtablishmentByDigitalName";
const _M_GET_ETABLIS_BY_ID = "getEtablishmentById";
const _M_GET_RAW_BY_ID = "getRawById";
const _M_CREATE = "create";
const _M_UPDATE = "update";
const _M_UPDATE_RAW = "updateRaw";

const DEF_ETABLISHMENT = {
  SLACH: "/",
  ETABLISHMENTS: `/establishments`,
  DIGITALNAMES: `/digitalnames`,
  DIRECTION: `/direction`,
  FIND_BY_DIGITAL_NAME: `/findbydigitalname`,
  ACTIVITIES: "/activities",
  SUBACTIVITIES: "/subactivities",
  ACTIVITIES_URL: "/activitydetails",
  SUPPORT_BY_FUNCTION: "/supportbyfunctions",
  PRODUCT_PRICES: "/productsprices",
  ACTIVITY_DETAILS: "/activitydetails" 
};

/**
 * Classe de manipulation des appels api concernant les établissements.
 */
export default class EtablishmentsApi {
  constructor(httpclient) {
    this.httpclient = httpclient;
  }

  /**Retourne la liste des noms digitaux des établissements */
  async getDigitalNames() {
    let url = this.httpclient.buildUrl(
      DEF_ETABLISHMENT.ETABLISHMENTS + DEF_ETABLISHMENT.DIGITALNAMES
    );
    logger.debugFull(_CLASS_NAME, _M_GET_DIGITAL_NAMES, "buildUrl : " + url);
    return this.httpclient.get(url);
  }

  /**retourne les données brutes des établissements (et pas la partie RAW) */
  async getEstablishments() {
    let url = this.httpclient.buildUrl(DEF_ETABLISHMENT.ETABLISHMENTS);
    return this.httpclient.get(url);
  }

  /**rechercher un établissement en fonction de son nom digital */
  async getEtablishmentByDigitalName(digitalName) {
    let url = this.httpclient.buildUrl(
      DEF_ETABLISHMENT.ETABLISHMENTS +
        DEF_ETABLISHMENT.FIND_BY_DIGITAL_NAME +
        DEF_ETABLISHMENT.SLACH +
        digitalName
    );
    logger.debugFull(
      _CLASS_NAME,
      _M_GET_ETABLIS_BY_DIGI_NAME,
      "buildUrl : " + url
    );
    return this.httpclient.get(url);
  }

  /**Retourne un établissement en fonction de son identifiant.*/
  async getEtablishmentById(etablishmentId) {
    let url = this.httpclient.buildUrl(
      DEF_ETABLISHMENT.ETABLISHMENTS +
        DEF_ETABLISHMENT.SLACH +
        etablishmentId.toString()
    );
    logger.debugFull(_CLASS_NAME, _M_GET_ETABLIS_BY_ID, "buildUrl : " + url);
    return this.httpclient.get(url);
  }

  /**Retourne les données au format RAW d'un établissement en fonction de l'identifiant.*/
  async getRawById(etablishmentId) {
    let url = this.httpclient.buildUrl(
      DEF_ETABLISHMENT.ETABLISHMENTS +
        DEF_ETABLISHMENT.SLACH +
        etablishmentId.toString() +
        DEF_ETABLISHMENT.DIRECTION
    );
    logger.debugFull(_CLASS_NAME, _M_GET_RAW_BY_ID, "buildUrl : " + url);
    return this.httpclient.get(url);
  }

  /**
   * Création d'un établissement
   * @param {*} establishment
   */
  async create(establishment) {
    let url = this.httpclient.buildUrl(DEF_ETABLISHMENT.ETABLISHMENTS);
    logger.debugFull(_CLASS_NAME, _M_CREATE, "buildUrl : " + url);
    return this.httpclient.post(url, establishment);
  }

  /**
   * mettre à jour un établissement
   * @param {*} etablishment
   */
  async update(etablishment) {
    let url = this.httpclient.buildUrl(
      DEF_ETABLISHMENT.ETABLISHMENTS +
        DEF_ETABLISHMENT.SLACH +
        etablishment.id.toString()
    );
    logger.debugFull(_CLASS_NAME, _M_UPDATE, "buildUrl : " + url);
    return this.httpclient.put(url, etablishment);
  }

  /**
   * supprimer un établissement
   * @param {*} etablishment
   */
  async deleteEtablishment(etablishmentId) {
    let url = this.httpclient.buildUrl(
      DEF_ETABLISHMENT.ETABLISHMENTS +
        DEF_ETABLISHMENT.SLACH +
        etablishmentId.toString()
    );
    return this.httpclient.delete(url, {});
  }

  /**
   * mettre à jour le RAW de l'établissement
   * @param {*} etablishmentId
   * @param {*} raw
   */
  async updateRaw(etablishmentId, raw) {
    let url = this.httpclient.buildUrl(
      DEF_ETABLISHMENT.ETABLISHMENTS +
        DEF_ETABLISHMENT.SLACH +
        etablishmentId.toString() +
        DEF_ETABLISHMENT.DIRECTION
    );
    logger.debugFull(_CLASS_NAME, _M_UPDATE_RAW, "buildUrl : " + url);
    return this.httpclient.put(url, raw);
  }

  async foo(status, result, delai = undefined) {
    let url = `http://localhost:3000/api/dev/call?status=${status}&result=${result}`;
    if (delai) {
      url += `&delai=${delai}`;
    }

    return this.httpclient.get(url);
  }

  /**retourne la liste des activités pour un établissement */
  async getActivities(etablishmentId) {
    let url = this.httpclient.buildUrl(
      DEF_ETABLISHMENT.ETABLISHMENTS +
        "/" +
        etablishmentId.toString() +
        DEF_ETABLISHMENT.ACTIVITIES
    );

    return this.httpclient.get(url);
  }

  /**
   * Retourne la liste des établissements pour une activité
  */
 async getEstablishmentOfActivity(activityId) {
   let url = this.httpclient.buildUrl(
      DEF_ETABLISHMENT.ETABLISHMENTS
      + DEF_ETABLISHMENT.ACTIVITIES
      + DEF_ETABLISHMENT.SLACH
      + activityId.toString()
   );
   return this.httpclient.get(url);
 }

  /** Récupération des urls par activités de l'établissement */
  async getUrlOFActivities(establishmentId) {
    let url = this.httpclient.buildUrl(
      DEF_ETABLISHMENT.ETABLISHMENTS
      + DEF_ETABLISHMENT.SLACH
      + establishmentId.toString()
      + DEF_ETABLISHMENT.ACTIVITIES_URL
    )
    return this.httpclient.get(url);
  }

  /**Ajouter une activité à l'établissement */
  async addActivity(etablishmentId, activityId) {
    let url = this.httpclient.buildUrl(
      DEF_ETABLISHMENT.ETABLISHMENTS +
        "/" +
        etablishmentId.toString() +
        DEF_ETABLISHMENT.ACTIVITIES +
        "/" +
        activityId
    );

    return this.httpclient.post(url, activityId);
  }

  /**retirer une activité à l'établissement */
  async removeActivity(etablishmentId, activityId) {
    let url = this.httpclient.buildUrl(
      DEF_ETABLISHMENT.ETABLISHMENTS +
        "/" +
        etablishmentId.toString() +
        DEF_ETABLISHMENT.ACTIVITIES +
        "/" +
        activityId
    );

    return this.httpclient.delete(url, activityId);
  }

  async establishmentsWithSubActivity(subActivityId) {
    let url = this.httpclient.buildUrl(
      DEF_ETABLISHMENT.ETABLISHMENTS +
        DEF_ETABLISHMENT.SUBACTIVITIES +
        "/" +
        subActivityId.toString()
    );
    return this.httpclient.get(url);
  }


  /** ajouter un couple activité - url à un établissement */
  async createUrlActivity(establishmentId, website) {
    let url = this.httpclient.buildUrl( 
      DEF_ETABLISHMENT.ETABLISHMENTS
      + DEF_ETABLISHMENT.SLACH
      + establishmentId.toString()
      + DEF_ETABLISHMENT.ACTIVITIES_URL
    )
    return this.httpclient.post(url, website);
  }

  /** mettre à jour un couple activité - url à un établissement */
  async updateUrlActivity(establishmentId, website) {
    let url = this.httpclient.buildUrl( 
      DEF_ETABLISHMENT.ETABLISHMENTS
      + DEF_ETABLISHMENT.SLACH
      + establishmentId.toString()
      + DEF_ETABLISHMENT.ACTIVITIES_URL
      + DEF_ETABLISHMENT.SLACH
      + website.activityId.toString()
    )
    return this.httpclient.put(url, website);
  }


  /** Récupération de l'ensemble des couples estab/fonction pour un estab */
  async getAllEstablishmentFunctionForEstablishment(establishmentId) {
    let url = this.httpclient.buildUrl(
      DEF_ETABLISHMENT.ETABLISHMENTS
      + DEF_ETABLISHMENT.SLACH
      + establishmentId.toString()
      + DEF_ETABLISHMENT.SUPPORT_BY_FUNCTION
    )
    return this.httpclient.get(url);
  }

  /** Création de l'ensemble estab/fonction */
  async createSupportForEstablishment(establishmentId, datas) {
    let url = this.httpclient.buildUrl(
      DEF_ETABLISHMENT.ETABLISHMENTS
      + DEF_ETABLISHMENT.SLACH
      + establishmentId.toString()
      + DEF_ETABLISHMENT.SUPPORT_BY_FUNCTION
    )
    return this.httpclient.post(url, datas);
  }

  /** Mise à jour de l'ensemble estab/fonction */
  async updateSupportForEstablishment(establishmentId, functionId, datas) {
    let url = this.httpclient.buildUrl(
      DEF_ETABLISHMENT.ETABLISHMENTS
      + DEF_ETABLISHMENT.SLACH
      + establishmentId.toString()
      + DEF_ETABLISHMENT.SUPPORT_BY_FUNCTION
      + DEF_ETABLISHMENT.SLACH
      + functionId.toString()
    )
    return this.httpclient.put(url, datas);
  }

  /** Suppression de l'ensemble estab/fonction */
  async deleteSupportForEstablishment(establishmentId, functionId) {
    let url = this.httpclient.buildUrl(
      DEF_ETABLISHMENT.ETABLISHMENTS
      + DEF_ETABLISHMENT.SLACH
      + establishmentId.toString()
      + DEF_ETABLISHMENT.SUPPORT_BY_FUNCTION
      + DEF_ETABLISHMENT.SLACH
      + functionId.toString()
    )
    return this.httpclient.delete(url);
  }

  /** Récupération de l'ensemble des details d'activité pour tous les établissements */
  async getAllEstablishmentActivityDetails() {
    let url = this.httpclient.buildUrl(
      DEF_ETABLISHMENT.ETABLISHMENTS
      + DEF_ETABLISHMENT.ACTIVITY_DETAILS
    )
    return this.httpclient.get(url);
  }

}
