import TemplateApi from "../template_api";

const DEFINES = {
  SLACH: "/",
  PATH: "/auth/user",
  DETAIL: "/auth/detail/user/",
  SERVICES: "/auth/detail/services",
  FUNCTIONS: "/auth/detail/functions",
  ROLES: "/auth/detail/roles",
  COLLABORATER: "/auth/detail/collaborater/",
  CUSTOMER: "/auth/detail/customer",
};

/**L'API DgAuth permet de contacter l'API de gestion des droits 'collaborateur'. Cette API se
 * base sur le token 'Authorisation' passé en paramètre pour déterminer les droits applicables
 * pour un collaborateur.
 */
export default class DgAuthApi extends TemplateApi {
  constructor(httpclient) {
    super(httpclient, DEFINES.PATH);

    this.httpclient = httpclient;
  }

  /** Retourne un token définissant les rôles d'un collaborateur. */
  async getRolesForUser() {
    let url = this.httpclient.buildUrl(this.path);

    return this.httpclient.post(url, {}).then((result) => {
      return result.token;
    });
  }

  // /** Retourne les rôles dgauth pour un client */
  // async getRolesForCustomer() {
  //   let url = this.httpclient.buildUrl(DEFINES.CUSTOMER);

  //   return this.httpclient.get(url);
  // }

  // /** Met à jour les rôles dgauth pour un client */
  // async updateRolesForCustomer(roles) {
  //   let url = this.httpclient.buildUrl(DEFINES.CUSTOMER);

  //   return this.httpclient.put(url, roles);
  // }

  /**Retourne le détail des rôles d'un collaborateur. Ne génère pas le token. */
  async getDetailForUser(userId) {
    let url = this.httpclient.buildUrl(DEFINES.DETAIL + userId.toString());
    return this.httpclient.get(url);
  }

  // /**Retourne le détail des rôles de tous les collaborateurs. Ne génère pas le token. */
  // async getDetailForAllCollaborater() {
  //   let url = this.httpclient.buildUrl(DEFINES.COLLABORATER);
  //   return this.httpclient.get(url);
  // }

  // /**Retourne le détail des rôles d'un collaborateur. Ne génère pas le token. */
  // async getDetailForCollaborater(userId) {
  //   let url = this.httpclient.buildUrl(
  //     DEFINES.COLLABORATER + userId.toString()
  //   );
  //   return this.httpclient.get(url);
  // }

  // /** Met à jour la liste des rôles d'un collaborateur */
  // async updateRolesForCollaborater(entity, entityId) {
  //   let url = this.httpclient.buildUrl(
  //     DEFINES.COLLABORATER + entityId.toString()
  //   );
  //   logger.debug("url to put  " + url);
  //   return this.httpclient.put(url, entity);
  // }

  /** Retourne les rôles pour l'entité "services". */
  // async getRolesForServices() {
  //   let url = this.httpclient.buildUrl(DEFINES.SERVICES);

  //   return this.httpclient.get(url);
  // }

  // /** Met à jour la liste des rôles d'un service */
  // async updateRolesForServices(entity) {
  //   let url = this.httpclient.buildUrl(
  //     DEFINES.SERVICES + "/" + entity.id.toString()
  //   );

  //   return this.httpclient.put(url, entity);
  // }

  // /** Retourne les rôles pour les fonctions. */
  // async getRolesForFonctions() {
  //   let url = this.httpclient.buildUrl(DEFINES.FUNCTIONS);

  //   return this.httpclient.get(url);
  // }

  /** Met à jour la liste des rôles d'une fonction */
  /*async updateRolesForFunction(entity) {
    let url = this.httpclient.buildUrl(
      DEFINES.FUNCTIONS + "/" + entity.id.toString()
    );

    return this.httpclient.put(url, entity);
  }*/

  /** Retourne la liste des rôles avec l'héritage. */
  // async getRoles() {
  //   let url = this.httpclient.buildUrl(DEFINES.ROLES);

  //   return this.httpclient.get(url);
  // }
}
